.green-audio-player {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #e1e7ec;
  line-height: 1;
  padding: 0px 10px;
  max-width: 500px;
  user-select: none;
}
  .dark-bg .green-audio-player {
    background: #fff;
  }

  .green-audio-player audio,
  .green-audio-player .hidden,
  .green-audio-player .controls-ui__message {
    display: none;
  }

  .green-audio-player .controls__play-pause-btn,
  .green-audio-player .controls__volume-btn,
  .green-audio-player .controls__download-link {
    width: 38px;
    min-width: 38px;
    height: 38px;
    padding: 10px;
    margin: -10px;
    cursor: pointer;
  }

  .green-audio-player > div,
  .slider-container > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .green-audio-player > div {
    padding: 10px;
  }
    .green-audio-player > div:first-child,
    .green-audio-player .controls__volume-btn,
    .green-audio-player .controls__download-link {
      line-height: 0;
    }
      .green-audio-player .controls-ui__loading > div {
        width: 18px;
        min-width: 18px;
        height: 18px;
        border: 2px solid #9ca8b5;
        border-right-color: transparent;
        border-radius: 50%;
        -webkit-animation: green-audio-player-loading 0.5s linear infinite;
        -o-animation: green-audio-player-loading 0.5s linear infinite;
        animation: green-audio-player-loading 0.5s linear infinite;
      }
        @keyframes green-audio-player-loading {
          from {
            transform: rotateZ(0);
          }

          to {
            transform: rotateZ(1turn);
          }
        }


      .green-audio-player .controls__play-pause-btn {
        background: url('/i/icon-button-play.svg') no-repeat 50% 50%/18px;
      }
        .green-audio-player .controls__play-pause-btn.play {
          background-image: url('/i/icon-button-pause.svg');
        }


  .green-audio-player > div:first-child + div,
  .slider-container > div {
    flex: 1 0 auto;
  }
    .green-audio-player > div:first-child + div > span {
      text-align: center;
      min-width: 45px;
    }

    .green-audio-player .controls__slider,
    .slider-container > div > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex: 1 0;
      margin: 0px 22px;
      position: relative;
      cursor: pointer;
    }
      .green-audio-player .controls__slider[data-direction="vertical"],
      .slider-container > div > div.ui-slider-vertical {
        justify-content: center;
        align-items: flex-end;
        height: 100%;
        margin: 0px;
      }

      .green-audio-player .controls__slider:before,
      .green-audio-player .controls__slider > div:before,
      .slider-container > div > div:before,
      .slider-container > div > div > div:before {
        content: '';
        display: block;
        background: #9ca8b5;
        border-radius: 99px;
        height: 4px;
        margin: auto;
        position: absolute;
        top: 0px;
        left: -7px;
        right: -7px;
        bottom: 0px;
      }
        .green-audio-player .controls__slider[data-direction="vertical"]:before,
        .green-audio-player .controls__slider[data-direction="vertical"] > div:before,
        .slider-container > div > div.ui-slider-vertical:before,
        .slider-container > div > div.ui-slider-vertical > div:before {
          width: 4px;
          height: unset;
          top: -7px;
          left: 0px;
          right: 0px;
          bottom: -7px;
        }

      .green-audio-player .controls__slider > div,
      .slider-container > div > div > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex: 0 0 auto;
        width: 0%;
        position: relative;
        pointer-events: none;
      }
      .slider-container > div > div > div {
        width: unset;
        position: absolute;
      }
        .green-audio-player .controls__slider[data-direction="vertical"] > div,
        .slider-container > div > div.ui-slider-vertical > div {
          justify-content: center;
          align-items: flex-start;
          width: auto;
        }
        .green-audio-player .controls__slider[data-direction="vertical"] > div {
          height: 0%;
        }

        .green-audio-player .controls__slider > div:before,
        .slider-container > div > div > div:before {
          background: #262526;
        }

        .green-audio-player .controls__slider > div > div,
        .slider-container > div > div > span {
          width: 20px;
          min-width: 20px;
          height: 20px;
          margin: 0px -10px 0px 0px;
          pointer-events: all;
          position: relative;
        }
        .slider-container > div > div > span {
          margin: 0px 0px 0px -10px;
        }
        .slider-container > div > div > span + span {
          margin: 0px 0px 0px -20px;
        }
          .green-audio-player .controls__slider[data-direction="vertical"] > div > div {
            margin: -10px 0px 0px;
          }
          .slider-container > div > div.ui-slider-vertical > span {
            margin: 0px 0px -10px;
          }
          .slider-container > div > div.ui-slider-vertical > span + span {
            margin: 0px 0px -20px;
          }

          .green-audio-player .controls__slider > div > div:after,
          .slider-container > div > div > span:after {
            content: '';
            background: #262526;
            border-radius: 50%;
            display: block;
            position: absolute;
            top: 3px;
            left: 3px;
            right: 3px;
            bottom: 3px;
          }



    .green-audio-player .controls__volume-btn {
      background: url('/i/icon-button-volume--max.svg') no-repeat 50% 50%/18px;
    }
      .green-audio-player .controls__volume.middle .controls__volume-btn {
        display: none;
      }

      .green-audio-player .controls__volume-btn[data-volume="mid"] {
        background-image: url('/i/icon-button-volume--mid.svg');
      }
      .green-audio-player .controls__volume-btn[data-volume="min"] {
        background-image: url('/i/icon-button-volume--min.svg');
      }



    .green-audio-player .controls__volume-slider {
      background-color: #e1e7ec;
      border-radius: 3px;
      box-shadow: 0px 3px 8px -4px rgba(0, 0, 0, 0.6);
      height: 90px;
      padding: 15px 4px;
      margin: 8px 0px;
      position: absolute;
      bottom: 100%;
      left: 50%;
      opacity: 0;
      visibility: hidden;
      z-index: 9;

      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      transform: translateX(-50%);

      -webkit-transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s;
      -moz-transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s;
      -o-transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s;
      transition: opacity 0.2s ease 0s, visibility 0s ease 0.2s;
    }
      .green-audio-player .controls__volume:focus .controls__volume-slider,
      .green-audio-player .controls__volume:hover .controls__volume-slider {
        opacity: 1;
        visibility: visible;

        -webkit-transition: opacity 0.2s ease 0s, visibility 0s ease 0s;
        -moz-transition: opacity 0.2s ease 0s, visibility 0s ease 0s;
        -o-transition: opacity 0.2s ease 0s, visibility 0s ease 0s;
        transition: opacity 0.2s ease 0s, visibility 0s ease 0s;
      }

      .green-audio-player .controls__volume-slider:before {
        content: '';
        display: block;
        position: absolute;
        top: -10px;
        left: -10px;
        right: -10px;
        bottom: -10px;
      }

      .green-audio-player .controls__volume.bottom .controls__volume-slider {
        top: 100%;
        bottom: auto;
      }
      .green-audio-player .controls__volume.middle .controls__volume-slider {
        background: none;
        box-shadow: none;
        height: 50px;
        padding: 7px 0px;
        margin: 0px;
        position: unset;
        bottom: auto;
        left: auto;
        opacity: 1;
        visibility: visible;

        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
      }

      .green-audio-player .controls__volume-slider .controls__slider[data-direction="vertical"] > div {
        height: 100%;
      }


    .green-audio-player .controls__download-link {
      background: url('/i/icon-button-download.svg') no-repeat 50% 50%/18px;
    }