/*
  Обозначения:
  _a (all) - применяет свойства к элементу и его дочерним элементам
  _h (hover) - применяет свойства к элементу при получении им фокуса (например, наведение указателя мыши)
  _i (important) - применяет свойства с повышенным приоритетом
*/
html, body {
  width: 100%;
}

body {
  background: #fff;
  overflow-x: hidden;
}

.semibold, .semibold_a, .semibold_a *, span.required { font-weight: 600; }

.bgcgray, .bgcgray_h:hover { background-color: #eef0f2; }
.bgcgray_i, .bgcgray_h_i:hover { background-color: #eef0f2 !important; }
.bgcmgray, .bgcmgray_h:hover { background-color: #e1e7ec; }
.bgcmgray_i, .bgcmgray_h_i:hover { background-color: #e1e7ec !important; }
.bgcdgray, .bgcdgray_h:hover { background-color: #262526; }
.bgcdgray_i, .bgcdgray_h_i:hover { background-color: #262526 !important; }

.cgray, .cgray_h:hover { color: #eef0f2; }
.cgray_i, .cgray_h_i:hover { color: #eef0f2 !important; }
.cmgray, .cmgray_h:hover { color: #e1e7ec; }
.cmgray_i, .cmgray_h_i:hover { color: #e1e7ec !important; }
.cdgray, .cdgray_h:hover { color: #262526; }
.cdgray_i, .cdgray_h_i:hover { color: #262526 !important; }

.brcgray, .brcgray_h:hover { border-color: #eef0f2; }
.brcgray_i, .brcgray_h_i:hover { border-color: #eef0f2 !important; }
.brcmgray, .brcmgray_h:hover { border-color: #e1e7ec; }
.brcmgray_i, .brcmgray_h_i:hover { border-color: #e1e7ec !important; }
.brcdgray, .brcdgray_h:hover { border-color: #262526; }
.brcdgray_i, .brcdgray_h_i:hover { border-color: #262526 !important; }



/* Components */
p { margin-bottom: 22px; }
p.before-ul { margin-bottom: 12px; }
p + p { margin-top: -8px; }
.subheader + p, .subsubheader + p { margin-top: -6px; }


.link {
  cursor: pointer;
}
.link,
.link.invert:hover {
  text-decoration: underline;
}
  .link:hover,
  .link.invert {
    text-decoration: none;
  }


.form-custom-popup-container,
.form-custom-popup-container.fancybox-content {
  width: 100%;
  max-width: 1340px;
}


.form-block-container {
}
  .form-popup-container .form-block-container {
    background: #fff;
    min-width: 400px;
    max-width: 700px;
    padding: 30px;
  }
    .form-custom-popup-container .form-block-container {
      max-width: unset;
    }

    .form-popup-container.fancybox-confirm .form-block-container {
      min-width: unset;
    }

  .form-title-wrapper {
    background: #eef0f2;
    padding: 20px 30px 0px;
    margin: -30px -30px 0px;
  }

  .form-title-wrapper + .form-container .form,
  .form-progress + .form {
    padding: 25px 0px 0px;
  }

  .form-block-container .subsubheader {
    padding-top: 0px;
    padding-right: 20px;
  }

  .form-wrapper {
    margin: -25px 0px 0px -25px;
  }
    .form-wrapper #form-success-message {
      padding: 25px 0px 0px 25px;
    }

    .form {
      position: relative;
    }
      .form-row {
        line-height: 1.25;
        padding: 25px 0px 0px 25px;
      }
        .form-label {
          display: none;
        }
          .form-row-checkbox .form-label,
          .form-row-checkboxlist .form-field > div > label > span > span + span,
          .form-row-radiolist .form-field > div > label > span > span + span {
            display: block;
            margin: 2px 0px 2px 10px;
            cursor: pointer;
          }
            .form-row-checkbox .form-label label {
              cursor: pointer;
            }

      .form-row-checkboxlist .form-field > div + div,
      .form-row-radiolist .form-field > div + div {
        margin: 8px 0px 0px;
      }
        .form-row-checkboxlist .form-field > div > label,
        .form-row-radiolist .form-field > div > label {
          display: inline-block;
        }

        .form-row-checkbox .form-field-wrapper,
        .form-row-checkboxlist .form-field > div > label > span,
        .form-row-radiolist .form-field > div > label > span {
          display: inline-flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
        }
          .form-row-checkbox .form-field,
          .form-row-checkboxlist .form-field > div,
          .form-row-radiolist .form-field > div {
            align-self: flex-start;
            position: relative;
          }
            .form-row-checkbox .form-field input,
            .form-row-checkboxlist .form-field > div > input,
            .form-row-radiolist .form-field > div > input {
              border: none;
              width: 0px;
              height: 0px;
              padding: 0px;
              margin: auto;
              top: 0px;
              left: 2px;
              bottom: 0px;
              position: absolute;
              opacity: 0;
              z-index: -1;
            }

            .form-row-checkbox .form-field > span,
            .form-row-checkboxlist .form-field > div > label > span > span:first-child,
            .form-row-radiolist .form-field > div > label > span > span:first-child {
              content: '';
              color: transparent;
              /*background: #fff;*/
              border: 1px solid #262526;
              border-radius: 5px;
              font-size: 0px;
              line-height: 0px;
              display: block;
              width: 24px;
              min-width: 24px;
              height: 24px;
              position: relative;
              cursor: pointer;
            }
            .form-row-radiolist .form-field > div > label > span > span:first-child {
              border-radius: 50%;
              width: 22px;
              min-width: 22px;
              height: 22px;
              margin: 1px;
            }
              .dark-bg .form-row-checkbox .form-field > span,
              .dark-bg .form-row-checkboxlist .form-field > div > label > span > span:first-child,
              .dark-bg .form-row-radiolist .form-field > div > label > span > span:first-child,
              .incut-2:not(.grey) .form-row-checkbox .form-field > span,
              .incut-2:not(.grey) .form-row-checkboxlist .form-field > div > label > span > span:first-child,
              .incut-2:not(.grey) .form-row-radiolist .form-field > div > label > span > span:first-child {
                /*background: transparent;*/
                border-color: #fff;
              }

              .form-row-checkbox .form-field > span label {
                content: '';
                color: transparent;
                display: block;
                font-size: 0px;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                margin: auto;
                cursor: pointer;
              }
                .form-row-checkbox .form-field > span label:after,
                .form-row-checkboxlist .form-field > div > label > span > span:first-child:after,
                .form-row-radiolist .form-field > div > label > span > span:first-child:after {
                  content: '';
                  background: #262526;
                  border-radius: 3px;
                  position: absolute;
                  top: 5px;
                  left: 5px;
                  right: 5px;
                  bottom: 5px;
                  margin: auto;
                  visibility: hidden;
                  opacity: 0;
                  transition: visibility 0s ease 0.3s, opacity 0.3s ease 0s;
                }
                .form-row-radiolist .form-field > div > label > span > span:first-child:after {
                  border-radius: 50%;
                }
                  .dark-bg .form-row-checkbox .form-field > span label:after,
                  .dark-bg .form-row-checkboxlist .form-field > div > label > span > span:first-child:after,
                  .dark-bg .form-row-radiolist .form-field > div > label > span > span:first-child:after,
                  .incut-2:not(.grey) .form-row-checkbox .form-field > span label:after,
                  .incut-2:not(.grey) .form-row-checkboxlist .form-field > div > label > span > span:first-child:after,
                  .incut-2:not(.grey) .form-row-radiolist .form-field > div > label > span > span:first-child:after {
                    background: #fff;
                  }

                  .form-row-checkbox .form-field input:checked + span label:after,
                  .form-row-checkboxlist .form-field > div > input:checked + label > span > span:first-child:after,
                  .form-row-radiolist .form-field > div > input:checked + label > span > span:first-child:after {
                    visibility: unset;
                    opacity: 1;
                    transition: visibility 0s ease 0s, opacity 0.3s ease 0s;
                  }

      .form-row-checkbox .errorMessage {
        margin-top: 0px;
      }


      .form-row-slider .form-field,
      .form-row-spinner .form-field {
        padding: 40px 0px 30px;
      }
        .slider-container > div > div > span > span {
          display: block;
          padding: 2px 2px 4px;
          left: 50%;
          bottom: 100%;
          position: absolute;
          transform: translateX(-50%);
        }
          .slider-container > div > div > span > span span {
            background: #fff;
            border-radius: 2px;
            box-shadow: 0px 1px 6px 0px rgb(0, 0, 0, 0.1);
            display: block;
            padding: 3px 10px;
          }


      .form-agreement-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
      }
        .form-agreement-wrapper .form-row-checkbox .form-field-wrapper {
          margin: 10px 0px 0px;
        }


        .input-container {
          /*background: #fff;*/
          border: 1px solid #262526;
          border-radius: 999px;
          padding: 12px 18px;
          position: relative;
          z-index: 1;
        }
          .dark-bg .input-container,
          .incut-2:not(.grey) .input-container {
            /*background: transparent;*/
            border-color: #fff;
          }

          .input-container input,
          .input-container textarea,
          .input-container select {
            color: #262526;
            background: transparent;
            font-size: 1em;
            font-weight: 400;
            line-height: 1.25;
            border: 0px;
            padding: 0px;
            margin: 0px;
          }
          .input-container input:not([type="radio"]):not([type="checkbox"]),
          .input-container textarea,
          .input-container select {
            width: 100%;
            min-width: 100%;
            max-width: 100%;
          }
            .input-container input::-webkit-input-placeholder { color: #262526; opacity: 0.7; }
            .input-container input:-moz-placeholder { color: #262526; opacity: 0.7; }
            .input-container input::-moz-placeholder { color: #262526; opacity: 0.7; }
            .input-container input:-ms-input-placeholder { color: #262526; opacity: 0.7; }
            .input-container input::-ms-input-placeholder { color: #262526; opacity: 0.7; }
            .input-container input::placeholder { color: #262526; opacity: 0.7; }
              .dark-bg .input-container input::-webkit-input-placeholder { color: #fff; }
              .dark-bg .input-container input:-moz-placeholder { color: #fff; }
              .dark-bg .input-container input::-moz-placeholder { color: #fff; }
              .dark-bg .input-container input:-ms-input-placeholder { color: #fff; }
              .dark-bg .input-container input::-ms-input-placeholder { color: #fff; }
              .dark-bg .input-container input::placeholder { color: #fff; }
              .incut-2:not(.grey) .input-container input::-webkit-input-placeholder { color: #fff; }
              .incut-2:not(.grey) .input-container input:-moz-placeholder { color: #fff; }
              .incut-2:not(.grey) .input-container input::-moz-placeholder { color: #fff; }
              .incut-2:not(.grey) .input-container input:-ms-input-placeholder { color: #fff; }
              .incut-2:not(.grey) .input-container input::-ms-input-placeholder { color: #fff; }
              .incut-2:not(.grey) .input-container input::placeholder { color: #fff; }

            .dark-bg .input-container input,
            .incut-2:not(.grey) .input-container input,
            .dark-bg .input-container textarea,
            .incut-2:not(.grey) .input-container textarea,
            .dark-bg .input-container select,
            .incut-2:not(.grey) .input-container select {
              color: #fff;
            }


        .input-container.js-spinner {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          max-width: 500px;
          padding: 0px;
        }
          .input-container.js-spinner > div:first-child {
            flex-grow: 1;
            order: 2;
            position: relative;
          }
            .input-container.js-spinner input {
              font-weight: 600;
              padding: 10px 0px;
              text-align: center;
              order: 2;
            }

          .input-container.js-spinner .ui-spinner-button {
            width: 0px;
            cursor: pointer;
            position: relative;
            z-index: 1;
          }
          .input-container.js-spinner .ui-spinner-down {
            order: 1;
          }
          .input-container.js-spinner .ui-spinner-up {
            order: 3;
          }

            .input-container.js-spinner .ui-spinner-button div {
              display: inline-block;
              width: 50px;
              min-width: 50px;
              height: 100%;
              position: relative;
            }
            .input-container.js-spinner .ui-spinner-down div {
              background: url('/i/icon-button-minus.svg') no-repeat 50% 50%/auto 18px transparent;
            }
            .input-container.js-spinner .ui-spinner-up div {
              background: url('/i/icon-button-plus.svg') no-repeat 50% 50%/auto 18px transparent;
              transform: translateX(-100%);
            }


        .form-error {
          position: relative;
        }
          .errorMessage {
            color: #fff;
            background: #262526;
            border-radius: 5px;
            font-size: 13px;
            line-height: 1.25;
            padding: 3px 5px;
            margin: 2px 0px 0px;
          }
            .dark-bg .errorMessage,
            .incut-2:not(.grey) .errorMessage {
              color: #262526;
              background: #fff;
            }


      .form-buttons,
      .incut-buttons {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
      }
      .incut-buttons {
        margin: -25px 0px 0px -25px;
      }
        .form-button,
        .incut-buttons > div {
          padding: 25px 0px 0px 25px;
        }


    .form-progress {
      background: #fff;
      border: solid #eef0f2;
      border-width: 1px 0px;
      height: 5px;
      position: relative;
    }
      .form-popup-container .form-progress {
        margin: 0px -30px;
      }

      .form-title-wrapper + .form-container .form-progress {
        border-top-width: 0px;
      }

      .form-progress > div {
        background: #262526;
        width: 0%;
        top: 0px;
        left: 0px;
        bottom: -1px;
        position: absolute;
        transition: width 0.3s ease 0s;
      }

  .form-steps {
    padding: 0px 30px 30px;
    margin: 0px -30px -30px;
    overflow: hidden;
    position: relative;
  }
    .form-steps .step {
      position: absolute;
      top: 0px;
      left: 30px;
      right: 30px;

      visibility: hidden;
      opacity: 0;
      transform: translateX(-30px);

      transition: visibility 0s ease 0.3s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
    }
    .form-steps .step.active ~ .step {
      transform: translateX(30px);
    }
    .form-steps .step.active {
      position: unset;
      visibility: unset;
      opacity: 1;
      transform: translateX(0px);

      transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
    }

      .form-steps .step > .subsubheader {
        font-size: 30px;
        text-transform: none;
      }

      .form-steps .vitrine-item > label {
        cursor: pointer;
      }
      .form-steps .vitrine-item > label > input {
        display: none;
      }

      .form-steps .vitrine-item > label > input + .vitrine-item-image:before {
        content: '';
        top: -8px;
        left: -8px;
        position: absolute;
        z-index: 3;

        visibility: hidden;
        transform: scale(0);
        transition: visibility 0s ease 0.3s, transform 0.3s ease 0s;
      }
        .form-steps .vitrine-item > label > input:checked + .vitrine-item-image:before {
          visibility: unset;
          transform: scale(1);
          transition: visibility 0s ease 0s, transform 0.3s ease 0s;
        }

      .form-steps .vitrine-item > label > input ~ .vitrine-item-text {
        transition: color 0s ease 0s, background 0.3s ease 0s;
      }
        .form-steps .vitrine-item > label > input:checked ~ .vitrine-item-text {
          color: #fff;
          background: #262526;
        }



      .form-steps .form-buttons {
        justify-content: space-between;
      }



.button-style,
.photo-text .zoom-image:after,
.gallery .zoom-image:after,
.gallery-list .vitrine-item-image > .zoom-image:after,
.gallery-list .vitrine-item-image > span > .zoom-image > span:after,
.video-frame > a:after {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #262526;
  background-color: #fff;
  border: 1px solid #262526;
  border-radius: 999px;
  box-shadow: 0px 0px 22px 0px rgba(38, 37, 38, 0.25);
  font-weight: 600;
  line-height: 1.25;
  text-align: center;
  padding: 12px 30px;
  cursor: pointer;
  user-select: none;
  position: relative;

  transition: color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
}
  .button-style[disabled],
  .button-style:disabled,
  .button-style.disabled {
    color: #959595 !important;
    background: #e7e7e7 !important;
    border-color: #e7e7e7 !important;
    cursor: default;
  }

  .button-style:hover,
  .button-style.active {
    color: #fff;
    background-color: #262526;
  }

  .button-style.invert,
  .main-rotator .button-style,
  .dark-bg .button-style,
  .incut-2:not(.grey) .button-style {
    color: #fff;
    background-color: #262526;
    border-color: #fff;
    box-shadow: 0px 0px 22px 0px rgba(255, 255, 255, 0.25);
  }
    .button-style.invert:hover,
    .button-style.invert.active,
    .main-rotator .button-style:hover,
    .main-rotator .button-style.active,
    .dark-bg .button-style:hover,
    .dark-bg .button-style.active,
    .incut-2:not(.grey) .button-style:hover,
    .incut-2:not(.grey) .button-style.active {
      color: #262526;
      background-color: #fff;
    }

  .tab-list .button-style {
    font-weight: 400;
    padding: 8px 25px;
    box-shadow: 0px 0px 10px 0px rgba(38, 37, 38, 0.1);
  }
    .dark-bg .tab-list .button-style {
      box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.1);
    }

  .button-style:before,
  .button-style:after {
    background: no-repeat 50% 50%/contain transparent;
    margin: -7px 12px -7px 0px;

    transition: background-image 0.2s ease-in-out 0s;
  }
  .button-style:after {
    margin-left: 12px;
    margin-right: 0px;
  }
    .button-style[disabled]:before,
    .button-style:disabled:before,
    .button-style.disabled:before,
    .button-style[disabled]:after,
    .button-style:disabled:after,
    .button-style.disabled:after {
      filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="saturate" values="0"/><feComponentTransfer><feFuncR type="table" tableValues="0.65 0.35"/><feFuncG type="table" tableValues="0.65 0.35"/><feFuncB type="table" tableValues="0.65 0.35"/></feComponentTransfer></filter></svg>#filter');
      -webkit-filter: grayscale(1) invert(0.65);
      filter: grayscale(1) invert(0.65);
    }

  .button-calc-icon:before,
  .button-callback-icon:before {
    content: '';
    background-image: url('/i/icon-button-calc.svg');
    width: 23px;
    min-width: 23px;
    height: 27px;
  }

  .button-callback-icon:before,
  .button-callback-icon.invert:hover:before,
  .button-callback-icon.invert.active:before,
  .main-rotator .button-callback-icon.active:before,
  .incut-2:not(.grey) .button-callback-icon:hover:before,
  .incut-2:not(.grey) .button-callback-icon.active:before,
  .dark-bg .button-callback-icon:hover:before,
  .dark-bg .button-callback-icon.active:before {
    background-image: url('/i/icon-button-callback.svg');
  }
    .button-callback-icon:hover:before,
    .button-callback-icon.active:before,
    .button-callback-icon.invert:before,
    .incut-2:not(.grey) .button-callback-icon:before,
    .dark-bg .button-callback-icon:before {
      background-image: url('/i/icon-button-callback--white.svg');
    }

  .button-prev:before,
  .button-next:after {
    content: '';
    width: 8px;
    height: 12px;
  }



.vitrine-grouped {
  visibility: hidden;
  opacity: 0;
  transform: translateY(50px);
  position: relative;
}
  .vitrine-grouped.unveil-loaded {
    visibility: unset;
    opacity: 1;
    transform: translateY(0);
    transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
  }

  div:not(.vitrine-grouped) > .vitrine,
  div:not(.vitrine-grouped) > .slick {
    visibility: hidden;
    opacity: 0;
    transform: translateY(50px);
  }
    div:not(.vitrine-grouped) > .vitrine.unveil-loaded,
    div:not(.vitrine-grouped) > .slick.unveil-loaded {
      visibility: unset;
      opacity: 1;
      transform: translateY(0);
      transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
    }
    div:not(.vitrine-grouped) > .vitrine:not(.active),
    div:not(.vitrine-grouped) > .slick:not(.active) {
      display: none;
    }

  .vitrine-grouped > .vitrine,
  .vitrine-grouped > .slick {
    position: absolute;
    top: 30px;
    left: 0px;
    right: 0px;
    visibility: hidden;
    opacity: 0;
    transform: translateX(-50px);
    transition: visibility 0s ease 0.3s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
  }
  .vitrine-grouped > .slick {
    top: 50px;
  }
    .vitrine-grouped > .vitrine.active ~ .vitrine,
    .vitrine-grouped > .slick.active ~ .slick {
      transform: translateX(50px);
    }
    .vitrine-grouped > .vitrine.active,
    .vitrine-grouped > .slick.active {
      position: unset;
      visibility: unset;
      opacity: 1;
      transform: translateX(0px);
      transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
    }



.slick {
  padding: 0px 0px 55px;
}
  .slick:not(.slick-initialized) > div:first-child {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: stretch;
    margin: -30px 0px 0px -30px;
  }

  .slick .slick-slider {
    overflow: hidden;
    padding: 50px 30px;
    margin: -50px -30px;
  }
    .slick .slick-list {
      margin: -30px 0px 0px -30px;
      overflow: unset !important;
    }
      .slick:not(.slick-initialized) .slick-list {
        width: calc(100% + 60px);
      }

      .slick .slick-track {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
      }
        .slick .slick-slide {
          float: none;
          height: unset;
        }

  .slick-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
    .slick-dots {
      flex: 1 1 auto;
    }
      .slick-dots ul {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
        padding: 28px 0px 0px;
      }
        .slick-dots li {
          flex: 1 1 auto;
          padding: 20px 0px;
          cursor: pointer;
          overflow: hidden;
          position: relative;
        }
          .slick-dots li div {
            border-bottom: 1px solid #e1e7ec;
            height: 4px;
            position: relative;
          }
            .dark-bg .slick-dots li div {
              border-bottom-color: #595959;
            }

            .slick-dots li div:before {
              content: '';
              background: #262526;
              width: 0%;
              position: absolute;
              top: 0px;
              right: 0px;
              bottom: 0px;

              transition: width 0.3s ease-in-out 0s;
            }
              .dark-bg .slick-dots li div:before {
                background: #fff;
              }

              .slick-dots li.slick-active div:before {
                width: 100%;
              }
              .slick-dots li.slick-active div:before,
              .slick-dots li:hover ~ li div:before,
              .slick-dots .invert-fix li div:before {
                left: 0px;
                right: auto;
              }
              .slick-dots .invert-fix li.slick-active div:before {
                left: auto;
                right: 0px;
              }

            .slick-dots li div:after {
              content: '';
              background: #262526;
              height: 0px;
              position: absolute;
              left: 0px;
              right: 0px;
              bottom: 0px;

              transition: height 0.15s ease-in-out 0s;
            }
              .dark-bg .slick-dots li div:after {
                background: #fff;
              }

              .slick-dots li:hover div:after {
                height: 100%;
              }


    .slick-arrows {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      flex: 0 0 auto;
      position: relative;
    }
      .slick-arrows .button-style,
      .photo-text .zoom-image:after,
      .gallery .zoom-image:after,
      .gallery-list .vitrine-item-image > .zoom-image:after,
      .gallery-list .vitrine-item-image > span > .zoom-image > span:after,
      .video-frame > a:after {
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: auto 18px;
        width: 44px;
        min-width: 44px;
        height: 44px;
        padding: 0px;
      }
      .slick-arrows .button-style {
        margin: 28px 0px 0px 25px;
      }

      .button-prev:hover:before,
      .button-prev.active:before,
      .button-prev.invert:before,
      .slick-arrows .button-style.prev {
        background-image: url('/i/arrow-left--white.svg');
      }
        .button-prev:before,
        .button-prev.invert:hover:before,
        .button-prev.invert.active:before,
        .button-prev[disabled]:before,
        .button-prev:disabled:before,
        .button-prev.disabled:before,
        .slick-arrows .button-style.prev.invert,
        .main-rotator .slick-arrows .button-style.prev,
        .dark-bg .slick-arrows .button-style.prev {
          background-image: url('/i/arrow-left--black.svg');
        }

      .button-next:hover:after,
      .button-next.active:after,
      .button-next.invert:after,
      .slick-arrows .button-style.next {
        background-image: url('/i/arrow-right--white.svg');
      }
        .button-next:after,
        .button-next.invert:hover:after,
        .button-next.invert.active:after,
        .button-next[disabled]:after,
        .button-next:disabled:after,
        .button-next.disabled:after,
        .slick-arrows .button-style.next.invert,
        .main-rotator .slick-arrows .button-style.next,
        .dark-bg .slick-arrows .button-style.next {
          background-image: url('/i/arrow-right--black.svg');
        }



.leader-icon {
  background: url('/i/icon-leader.svg') no-repeat 50% 50%/contain transparent;
  width: 130px;
  top: 45px;
  right: 0px;
  position: absolute;
  z-index: 3;

  opacity: 0;
}
  .leader-icon.unveil-loaded {
    opacity: 1;
    transition: opacity 0.3s ease 0s;
  }

  .leader-icon:before {
    content: '';
    display: block;
    background: url('/i/icon-leader-text.svg') no-repeat 50% 50%/contain transparent;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    transform: rotate(115deg);
    transition: transform 1.4s ease-in-out 0.15s;
  }
    .leader-icon.unveil-loaded:before {
      transform: rotate(0);
    }

  .leader-icon:after {
    content: '';
    display: block;
    height: 0px;
    padding-top: 100%;
  }



ul.ul, ol.ol {
  margin: -6px 0px 35px;
}
ol.ol { counter-reset: ol-list-counter; }
  ul.ul ul.ul, ol.ol ol.ol {
    padding-left: 12px;
    margin-top: 0px;
    margin-bottom: 12px;
  }
  ul.ul li, ol.ol li {
    padding-top: 10px;
    padding-bottom: 0px;
    position: relative;
  }
    ul.ul li {
      padding-left: 20px;
    }
      ul.ul li:before {
        content: '●';
        color: transparent;
        display: inline-block;
        background: url('/i/ul-list-style-image.svg') no-repeat 50% 50%/contain transparent;
        width: 8px;
        margin: 0px 10px 0px -18px;
      }
        .dark-bg ul.ul li:before,
        .incut-2:not(.grey) ul.ul li:before {
          background-image: url('/i/ul-list-style-image--white.svg');
        }

      ol.ol li:before {
        content: counters(ol-list-counter, '.') '. ';
        counter-increment: ol-list-counter;
        font-size: 1.25em;
        font-weight: 700;
      }




.socials {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
  .socials > div {
    padding: 0px 15px 0px 0px;
  }
    .socials a {
      display: block;
      background: url('/i/socials.svg') no-repeat;
      line-height: 0;
      width: 22px;
      min-width: 22px;
      height: 22px;
      cursor: pointer;
    }
    /*.socials a.icon-facebook  { background-position: 0 0; }*/ /* не обязательно объявлять этот класс */
    .socials a.icon-telegram  { background-position: 0 -23px; }
    .socials a.icon-vk        { background-position: 0 -46px; }
    .socials a.icon-instagram { background-position: -23px 0; }
    .socials a.icon-tiktok    { background-position: -23px -23px; }
    .socials a.icon-whatsapp  { background-position: -23px -46px; }
    .socials a.icon-ok        { background-position: -46px 0; }
    .socials a.icon-twitter   { background-position: -46px -23px; }
    .socials a.icon-youtube   { background-position: -46px -46px; }
    .socials a.icon-pinterest { background-position: -69px 0; }
    .socials a.icon-viber     { background-position: -69px -23px; }
    .socials a.icon-zen       { background-position: -69px -46px; }



.js-audio-player audio {
  display: block;
  max-width: 100%;
  min-width: 100%;
  height: 30px;
}



.price-separator {
  font-size: inherit;
  width: 0px;
  height: 0px;
  padding: 0em 0.09375em;
}



.unwrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}
  .unwrapper > div {
    width: 100vw;
    min-width: 100vw;
  }

.wrapper {
  width: 100%;
  max-width: 1440px;
  border: solid transparent;
  border-width: 0px 45px;
  margin: 0px auto;
  padding: 0px;
  position: relative;
}



.js-visible {
  visibility: hidden;
  opacity: 0;
  transform: translateY(50px);
}
  .js-visible.unveil-loaded {
    visibility: unset;
    opacity: 1;
    transform: translateY(0);
    transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
  }



.text-container {
  padding: 0px 0px 50px;
}



.video-frame {
  padding-top: 56%;
  position: relative;
  overflow: hidden;
}
  .video-frame > a {
    background: no-repeat 50% 50%/cover;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }
    .video-frame > a.unveil-loaded {
      visibility: unset;
      opacity: 1;
      transition: visibility 0s ease 0s, opacity 0.3s ease 0s;
    }

  .video-frame > iframe {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
    .video-frame.active > iframe {
      display: block;
    }
/* Components end */



/* Structure */
  .structure {
    overflow: hidden;
    transform-origin: center top;
  }


  /* Header */
  .header {
    background: #fff;
    position: relative;
    z-index: 5;
  }
    .header-top {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px 0px;
      position: relative;
      z-index: 1;
    }
      .header-top > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
      }

      .header-top-left {
        flex-basis: 100%;
        justify-content: flex-start;
      }
        .header-top-left .socials {
          order: 1;
        }

        .city-chooser {
          order: 2;
        }
          .socials + .city-chooser {
            margin: 0px 0px 0px 15px;
          }

          .city-chooser > div {
            position: relative;
          }
            .city-name {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              line-height: 1;
              font-weight: 600;
              cursor: pointer;
            }
              .city-name:before {
                content: '';
                background: url('/i/icon-placemark-bold.svg') no-repeat 50% 50%/contain transparent;
                width: 18px;
                min-width: 18px;
                height: 18px;
                margin: 0px 4px 0px 0px;
              }

              .city-name svg {
                width: 10px;
                min-width: 10px;
                margin: 0px 0px 0px 6px;
              }
                .city-name svg line {
                  fill: none;
                  stroke: #262526;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 3;
                  transition: transform 0.2s ease 0s;
                }
                .city-name svg line.line-left {
                  transform-origin: 1.5px 3.5px;
                }
                  .city-wrapper:hover .city-name svg line.line-left {
                    transform: translateY(6px) rotate(-78.95deg);
                  }

                .city-name svg line.line-right {
                  transform-origin: 18.5px 3.5px;
                }
                  .city-wrapper:hover .city-name svg line.line-right {
                    transform: translateY(6px) rotate(78.95deg);
                  }


            .city-list {
              position: absolute;
              top: 100%;
              left: 0;
              padding: 15px 0px 0px;

              visibility: hidden;
              opacity: 0;
            }
              .is-desktop-events .city-list,
              .is-mobile-events .city-list {
                transition: visibility 0s ease 0.2s, opacity 0.2s ease 0s, padding 0.2s ease 0s, margin 0.2s ease 0s;
              }

              .city-wrapper:hover .city-list {
                padding: 5px 0px 0px;
                visibility: unset;
                opacity: 1;
                transition: opacity 0.2s ease 0s, padding 0.2s ease 0s, margin 0.2s ease 0s;
              }

              .city-list div {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: stretch;
                background: #fff;
                box-shadow: 0px 7px 40px 0px rgba(0, 0, 0, 0.1);
                padding: 12px 20px 18px;
              }
                .city-list a {
                  margin: 5px 0px 0px;
                  cursor: pointer;
                }
                  .city-list a:hover {
                    text-decoration: underline;
                  }


              .city-chooser select {
                display: none;
              }




      .header-logo {
        flex-basis: 50%;
        line-height: 0;
      }
        .header-top-left + .header-logo {
          justify-content: center;
        }

        .header-logo img {
          max-width: min(213px, 100%);
        }


      .header-top-right {
        flex-basis: 100%;
        justify-content: flex-end;
      }
        .header-phones {
          font-size: 22px;
          font-weight: 600;
          line-height: 1.1;
        }

        .header-top-right .callback-link {
          padding: 0px 0px 0px 15px;
        }
          .header-top-right .callback-link .button-style,
          #contacts-popup .callback-link .button-style {
            font-size: 15px;
            font-weight: 400;
            box-shadow: 0px 0px 10px 0px rgba(38, 37, 38, 0.1);
            padding: 7px 15px;
          }
          .header-top-right .callback-link .button-style {
            text-transform: lowercase;
          }
            .header-top-right .callback-link .button-style:before,
            #contacts-popup .callback-link .button-style:before {
              width: 16px;
              min-width: 16px;
              height: 16px;
              margin-right: 8px;
            }



    .header-menu-wrapper {
      padding: 2px 0px 12px;
    }
      .header-menu-button-wrapper,
      .header-menu-checkbox,
      .header-menu .dropmarker {
        display: none;
      }
        .header-menu-button {
          width: 50px;
          min-width: 50px;
          padding: 7px 13px;
          box-shadow: 0px 0px 10px 0px rgba(38, 37, 38, 0.1);
        }
          .header-menu-button:before {
            transform-origin: 0% 0%;
          }
            .menu-is-open .header-menu-button:before {
              background: #fff;
              transform: translate(3.5px, -2px) rotate(45deg);
              margin-right: -1px;
            }
          .header-menu-button:after {
            transform-origin: 0% 100%;
          }
            .menu-is-open .header-menu-button:after {
              background: #fff;
              transform: translate(3.5px, 2px) rotate(-45deg);
              margin-right: -1px;
            }

      .header-menu {
        /*position: relative;*/
        line-height: 1.2;
      }
        .header-menu ul {
          display: block;
        }
        .header-menu > ul {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: stretch;
        }
          .header-menu ul > li {
            flex: 1 1 auto;
            position: relative;
          }
          .header-menu > ul > li {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
          }
              .header-menu ul > li > div > div {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;
                position: relative;
              }
              .header-menu > ul > li > div > div {
                padding: 0px;
              }
                .header-menu ul div > a {
                  cursor: pointer;
                }
                  .header-menu ul div > a:hover {
                    text-decoration: underline;
                  }
                  .header-menu li.active > div > div div > a {
                    font-weight: 700;
                  }

                .header-menu > ul > li > div > div > div > a,
                .header-menu > ul > li > div > div > div > span {
                  text-transform: uppercase;
                }


              .header-menu .icn-burger > div > div > div:first-child,
              .header-menu-button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: stretch;
                position: relative;
                border: 1px solid #262526;
                border-radius: 999px;
                cursor: pointer;

                transition: background-color 0.2s ease 0s;
              }
                .is-desktop-events .header-menu .icn-burger > div:hover > div > div:first-child,
                .menu-is-open .header-menu-button {
                  background: #262526;
                }

                .header-menu .icn-burger > div > div > div:first-child:before,
                .header-menu .icn-burger > div > div > div:first-child:after,
                .header-menu .icn-burger > div > div > div:first-child span,
                .header-menu-button:before,
                .header-menu-button:after,
                .header-menu-button span {
                  content: '';
                  font-size: 0px;
                  background: #262526;
                  border-radius: 9px;
                  height: 2px;
                  padding: 0px;
                  position: relative;
                }
                .is-desktop-events .header-menu .icn-burger > div > div > div:first-child:before,
                .is-desktop-events .header-menu .icn-burger > div > div > div:first-child:after,
                .header-menu-button:before,
                .header-menu-button:after {
                  transition: background-color 0.2s ease 0s, transform 0.2s ease 0s;
                }

                .header-menu .icn-burger > div > div > div:first-child span,
                .header-menu-button span {
                  margin: 4px 0px;
                  transition: opacity 0.2s ease 0s;
                }
                  .is-desktop-events .header-menu .icn-burger > div:hover > div > div:first-child span,
                  .menu-is-open .header-menu-button span {
                    opacity: 0;
                  }


          @media screen and (min-width: 1024px) {
            .header-menu > ul > li > div {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-end;
            }
              .header-menu ul > li > div > div {
                padding: 18px 0px 0px;
              }
                .header-menu > ul > li > div > div > div > a,
                .header-menu > ul > li > div > div > div > span {
                  display: inline-block;
                  font-size: 19px;
                  padding: 8px 0px;
                  position: relative;
                }
                  .header-menu > ul > li > div:hover > div div > a {
                    text-decoration: underline;
                  }

                  .header-menu > ul > li:not(.icn-burger).with-active > div > div div > a:after,
                  .header-menu > ul > li:not(.icn-burger).with-active > div > div div > span:after {
                    content: '';
                    display: block;
                    background: #262526;
                    width: 15px;
                    height: 1px;
                    margin: auto;
                    left: 0;
                    right: 0;
                    bottom: 5px;
                    position: absolute;

                    transition: opacity 0.3s ease 0s;
                  }
                    .header-menu > ul > li.with-active > div:hover > div div > a:after,
                    .header-menu > ul > li.with-active > div:hover > div div > span:after {
                      opacity: 0;
                    }


            .header-menu .icn-burger {
              flex-grow: 0;
            }
              .header-menu .icn-burger > div > div {
                padding: 0px 0px 3px;
              }
                .header-menu .icn-burger > div > div > div:first-child {
                  width: 58px;
                  min-width: 58px;
                  padding: 9px 16px;
                }
                  .header-menu .icn-burger > div > div > div:first-child:before {
                    transform-origin: 100% 0%;
                  }
                    .is-desktop-events .header-menu .icn-burger > div:hover > div > div:first-child:before {
                      background: #fff;
                      transform: translate(-4px, -2.5px) rotate(-45deg);
                      margin-left: -1px;
                    }

                  .header-menu .icn-burger > div > div > div:first-child:after {
                    transform-origin: 100% 100%;
                  }
                    .is-desktop-events .header-menu .icn-burger > div:hover > div > div:first-child:after {
                      background: #fff;
                      transform: translate(-4px, 2.5px) rotate(45deg);
                      margin-left: -1px;
                    }
          }


          .header-menu > ul ul {
            display: none;
          }
          .is-desktop-events .header-menu > ul ul {
            display: block;
          }
          .is-desktop-events .header-menu > ul > li > div > ul {
            background: #fff;
            box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.09);
            top: 100%;
            left: 0px;
            min-width: 100%;
            padding: 10px 35px 35px;
            white-space: nowrap;
            position: absolute;

            visibility: hidden;
            opacity: 0;
          }
          .is-desktop-events .header-menu > ul > li > div > ul {
            transform: translateY(15px);
            transition: visibility 0s ease 0.2s, opacity 0.2s ease 0s, transform 0.2s ease 0s;
          }
            .is-desktop-events .header-menu > ul > li > div:hover > ul {
              visibility: unset;
              opacity: 1;
              transform: translateY(0);
              transition: visibility 0s ease 0s, opacity 0.2s ease 0s, transform 0.2s ease 0s;
            }

            .is-desktop-events .header-menu ul > li.with-groups > div > ul,
            .is-desktop-events .header-menu > ul > li.icn-burger > div > ul {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: stretch;
              overflow: hidden;
              column-gap: 70px;
            }
              .is-desktop-events .header-menu .icn-burger > div > ul > li:not(.burger-info-item) + li.burger-info-item {
                margin-left: 30px;
              }
                .is-desktop-events .header-menu .icn-burger > div > ul > li:not(.burger-info-item) + li.burger-info-item:before {
                  content: '';
                  display: block;
                  background: #e1e7ec;
                  width: 2px;
                  top: 18px;
                  left: -51px;
                  bottom: 0px;
                  position: absolute;
                }

              .is-desktop-events .header-menu ul > li.with-groups > div > ul > li > div > div div > a,
              .is-desktop-events .header-menu ul > li.with-groups > div > ul > li > div > div div > span,
              .is-desktop-events .header-menu > ul > li.icn-burger > div > ul > li > div > div div > a,
              .is-desktop-events .header-menu > ul > li.icn-burger > div > ul > li > div > div div > span {
                font-size: 19px;
                text-transform: uppercase;
              }
  /* Header end */



  /* Content block */
  .content {
    position: relative;
    /*overflow: hidden;*/
    z-index: 3;

    /*min-height: 3000px;*/
  }
    .content > .wrapper:first-child {
      border-top: 1px solid #e1e7ec;
    }


    .main-rotator-section {
      padding: 0px 0px 55px;
    }
      .main-rotator-section > div {
        background: #262526;
      }
        .main-rotator {
          margin: 0px -45px;
        }
          .main-rotator .slick-arrows {
            height: 0px;
            bottom: 30px;
            right: 45px;
          }


          .main-rotator-slide {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            padding: 0px 0px 0px 45px;
          }
            .main-rotator > .main-rotator-slide ~ div {
              display: none;
            }

            .main-rotator-slide > div:first-child {
              flex: 860 4 860px;
              order: 2;
            }
              .main-rotator-slide > div:first-child > div {
                background: no-repeat 50% 50%/cover transparent;
                height: 100%;
                filter: blur(20px);
                position: relative;
              }
                .main-rotator-slide > div:first-child > div.unveil-loaded {
                  filter: blur(0);
                  transition: background-image 0.2s ease 0s, filter 0.3s ease-in-out 0.1s;
                }

                .main-rotator-slide > div:first-child > div:after {
                  content: '';
                  display: block;
                  height: 0px;
                  padding-top: 60%;
                }

                .main-rotator-slide > div:first-child > div a,
                .main-rotator-slide > div:first-child > div span {
                  background: #262526;
                  display: block;
                  top: 0px;
                  left: 0px;
                  right: 0px;
                  bottom: 0px;
                  position: absolute;
                }
                .main-rotator-slide > div:first-child > div a {
                  cursor: pointer;
                }


            .main-rotator-slide > div + div {
              color: #fff;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: stretch;
              flex: 535 1 535px;
              order: 1;
              padding: 0px 50px 80px 0px;
              overflow: hidden;
            }
              .main-rotator-slide > div + div .subheader {
                font-size: 54px;
                text-align: left;
              }
                .main-rotator-slide > div + div .subheader.hidden {
                  font-size: 0px;
                  padding: 0px;
                  margin: 0px;
                  width: 0px;
                  height: 0px;
                }

              .main-rotator-text {
                font-size: 20px;
                font-weight: 300;
                padding-bottom: 50px;

                visibility: hidden;
                opacity: 0;
                transform: translateY(50px);
              }
                .subheader.unveil-loaded + .main-rotator-text {
                  visibility: unset;
                  opacity: 1;
                  transform: translateY(0);
                  transition: visibility 0s ease 0s, opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
                }
                .subheader.hidden.unveil-loaded + .main-rotator-text {
                  transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
                }

              .main-rotator-buttons {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-content: flex-start;
                align-items: flex-start;
                margin: -15px 0px 0px -15px;

                visibility: hidden;
                opacity: 0;
                transform: translateY(50px);
              }
                .subheader.unveil-loaded ~ .main-rotator-buttons,
                .subheader.hidden.unveil-loaded + .main-rotator-text + .main-rotator-buttons {
                  visibility: unset;
                  opacity: 1;
                  transform: translateY(0);
                  transition: visibility 0s ease 0s, opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
                }
                .subheader.unveil-loaded + .main-rotator-text + .main-rotator-buttons {
                  transition: visibility 0s ease 0s, opacity 0.3s ease 0.2s, transform 0.3s ease 0.2s;
                }

                .main-rotator-buttons > div {
                  padding: 15px 0px 0px 15px;
                }
                  .main-rotator-buttons .button-style {
                    font-size: 20px;
                  }


    .breadcrumbs {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: flex-start;
      line-height: 1.1;
      padding: 25px 0px 45px;
      position: relative;

      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
      transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
    }
      .breadcrumbs.unveil-loaded {
        visibility: unset;
        opacity: 1;
        transform: translateY(0);
      }

      .main-rotator-section + .wrapper > .breadcrumbs {
        padding-top: 10px;
      }

      .breadcrumbs li {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 2px 0px 0px;
      }
        .breadcrumbs li:after,
        .breadcrumbs a,
        .breadcrumbs span {
          color: #959595;
          line-height: 1.3;
          vertical-align: baseline;
        }
        .breadcrumbs li:after {
          content: '>';
          margin: auto 4px;
        }
          .breadcrumbs li:last-child:after {
            display: none;
          }

        .breadcrumbs a {
          color: #262526;
          cursor: pointer;
          text-decoration: underline;
        }
          .breadcrumbs a:hover {
            text-decoration: none;
          }


    .subheader,
    .subsubheader,
    .subsubheader-top {
      margin: 0px;
      text-transform: uppercase;
      position: relative;
      z-index: 2;

      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
      transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
    }
      .subheader.unveil-loaded,
      .subsubheader.unveil-loaded,
      .subsubheader-top.unveil-loaded,
      .form-block-container .subheader,
      .form-block-container .subsubheader,
      .form-block-container .subsubheader-top,
      .incut-2 .subheader,
      .incut-2 .subsubheader,
      .incut-2 .subsubheader-top {
        visibility: unset;
        opacity: 1;
        transform: translateY(0);
      }
      .form-block-container .subheader,
      .form-block-container .subsubheader,
      .form-block-container .subsubheader-top,
      .incut-2 .subheader,
      .incut-2 .subsubheader,
      .incut-2 .subsubheader-top {
        transition: none;
      }

      .dark-bg .subheader,
      .dark-bg .subsubheader,
      .dark-bg .subsubheader-top {
        color: #fff;
      }

      .subheader *,
      .subsubheader *,
      .subsubheader-top * {
        text-transform: uppercase;
      }

      .subheader {
        font-size: 50px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: 0.03em;
        text-align: center;
        padding: 55px 0px 42px;
      }
        .breadcrumbs + .subheader {
          padding-top: 10px;
        }

        .incut-image .subheader {
          text-align: left;
        }


      .subsubheader,
      .subsubheader-top {
        font-size: 34px;
        font-weight: 500;
        line-height: 1.3;
        padding: 25px 0px 20px;
      }
        .subheader + .subsubheader,
        .subsubheader-top {
          padding-top: 0px;
        }

    .subheader-notice {
      font-size: 20px;
      text-align: center;
      padding: 0px 0px 38px;

      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }
      .subheader + .subheader-notice {
        margin-top: -24px;
      }
      .subheader.unveil-loaded + .subheader-notice,
      .subsubheader.unveil-loaded + .subheader-notice,
      .subsubheader-top.unveil-loaded + .subheader-notice {
        visibility: unset;
        opacity: 1;
        transform: translateY(0);
        transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
      }

      .incut-2 .subheader-notice {
        text-align: left;
      }
      .dark-bg .subheader-notice {
        font-weight: 300;
      }



    .incut {
      font-size: 26px;
      text-align: center;
      background: #eef0f2;
      margin: 60px 0px;
      position: relative;

      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }
      .incut.unveil-loaded {
        visibility: unset;
        opacity: 1;
        transform: translateY(0);
        transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
      }

      .incut:before {
        content: 'interior * renovation * design * interior * renovation * design';
        color: #eef0f2;
        font-size: 100px;
        font-weight: 300;
        line-height: 1;
        letter-spacing: 0.1em;
        text-align: center;
        text-transform: uppercase;
        position: absolute;
        top: -15px;
        left: 50%;
        white-space: nowrap;

        visibility: hidden;
        opacity: 0;
        transform: translate(calc(-50% + 50px), -50%);
      }
        .incut.unveil-loaded:before {
          visibility: unset;
          opacity: 1;
          transform: translate(-50%, -50%);
          transition: visibility 0s ease 0s, opacity 0.3s ease-out 0.2s, transform 0.3s ease-out 0.2s;
        }

      .incut > div > .wrapper {
        padding: 40px 30px;
      }


    .incut-2 {
      padding: 60px 0px;

      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }
    .incut-2:not(.grey) {
      color: #fff;
    }

      .incut-2.unveil-loaded {
        visibility: unset;
        opacity: 1;
        transform: translateY(0);
        transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
      }

      .incut-2 > div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        box-shadow: 0px 7px 40px 0px rgba(0, 0, 0, 0.2);
        position: relative;
      }
        .incut-image.invert > div {
          flex-direction: row-reverse;
        }

        .incut-2 > div:before,
        .incut-2 > div:after {
          content: '';
          background: #fff;
          border: 1px solid #e1e7ec;
          border-radius: 10px;
          top: 50%;
          position: absolute;
          z-index: -1;
        }
        .incut-2 > div:before {
          left: 2.7%;
          right: 2.7%;
          bottom: -16px;
        }
        .incut-2 > div:after {
          left: 1.5%;
          right: 1.5%;
          bottom: -10px;
        }

        .incut-image > div > div:first-child {
          background: no-repeat 50% 50%/cover #262526;
          width: 50%;
        }
          .incut-image.grey > div > div:first-child {
            background-color: #eef0f2;
          }
          .incut-image.dark-grey > div > div:first-child {
            background-color: #595959;
          }

        .incut-image > div > div:first-child:not([style]) {
          background-image: url('/f/incut_image/incut-image-bg.webp');
        }
          .no-webp .incut-image > div > div:first-child:not([style]) {
            background-image: url('/f/incut_image/incut-image-bg.jpg');
          }

          .incut-image.invert > div > div:first-child:not([style]) {
            background-image: url('/f/incut_image/incut-image-invert-bg.webp');
          }
            .no-webp .incut-image.invert > div > div:first-child:not([style]) {
              background-image: url('/f/incut_image/incut-image-invert-bg.jpg');
            }

          .incut-image > div > div:first-child:after {
            content: '';
            display: block;
            padding: 60% 0% 0%;
          }

        .incut-2:not(.incut-image) > div > div:first-child,
        .incut-image > div > div + div {
          background: #262526;
          padding: 55px 55px 60px 55px;
        }
        .incut-image > div > div + div {
          width: 50%;
          padding: 55px 60px 60px 55px;
        }
          .incut-2:not(.incut-image).grey > div > div:first-child,
          .incut-image.grey > div > div + div {
            background: #eef0f2;
          }
          .incut-2:not(.incut-image).dark-grey > div > div:first-child,
          .incut-image.dark-grey > div > div + div {
            background: #595959;
          }

          .incut-2:not(.incut-image) > div > div:first-child .subheader:first-child,
          .incut-2:not(.incut-image) > div > div:first-child .subsubheader:first-child,
          .incut-image > div > div + div .subheader:first-child,
          .incut-image > div > div + div .subsubheader:first-child {
            padding-top: 0px;
          }



    .tab-list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: center;
      padding: 0px 0px 30px;
      margin: -15px 0px 0px -15px;
      position: relative;
      z-index: 1;

      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }
      .tab-list.unveil-loaded {
        visibility: unset;
        opacity: 1;
        transform: translateY(0);
        transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
      }

      .tab-list > div {
        margin: 15px 0px 0px 15px;
      }


    .dark-bg,
    .grey-bg,
    .grey-bg-2 {
      padding: 40px 0px 50px;
    }
      .dark-bg > div {
        color: #fff;
        background: #262526;
        overflow: hidden;
        position: relative;
      }
        .dark-bg > div > object,
        .grey-bg-2 > div > object {
          display: none;
          height: 100%;
          top: 0px;
          right: 0px;
          position: absolute;
        }
        .grey-bg-2 > div > object {
          left: 0px;
          right: auto;
        }
          .dark-bg.unveil-loaded > div > object,
          .grey-bg-2.unveil-loaded > div > object {
            display: block;
          }

      .grey-bg > div,
      .grey-bg-2 > div {
        background: #eef0f2;
        overflow: hidden;
        position: relative;
      }


    .circle-bg > div {
      position: relative;
    }
      .circle-bg > div:before {
        content: '';
        background: url('/i/circle-bg.svg') no-repeat 50% 0%/contain transparent;
        display: block;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        position: absolute;
        opacity: 0;
      }
        .circle-bg.unveil-loaded > div:before {
          opacity: 1;
          transition: opacity 0.3s ease 0s;
        }

      .circle-bg > div > object {
        display: none;
        height: calc(100% + 200px);
        top: 0px;
        left: 0px;
        position: absolute;
      }
      .circle-bg > div > object + object {
        left: auto;
        right: 0px;
      }
        .circle-bg.unveil-loaded > div > object {
          display: block;
        }

      .circle-bg > div > .wrapper {
        padding-top: 20px;
      }



    .gallery-params,
    .gallery-notice {
      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }
    .gallery-params {
      padding: 0px 0px 30px;
    }
      .gallery-params.unveil-loaded,
      .gallery-notice.unveil-loaded {
        visibility: unset;
        opacity: 1;
        transform: translateY(0);
        transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
      }

      .gallery-params .vitrine-item-icons .vitrine-icon-address {
        flex: initial;
      }

    .gallery > div {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-content: flex-start;
      align-items: flex-start;

      line-height: 1.3;
      padding: 0px 0px 70px;
      margin: -35px 0px 0px -30px;
      position: relative;
    }
      .gallery > div > div {
        font-size: 14px;
        padding: 35px 0px 0px 30px;
        position: relative;
      }
        .gallery .width-1 {
          width: 100%;
        }
        .gallery .width-2 {
          width: 50%;
        }
        .gallery .width-3 {
          width: 33.33333333%;
        }
        .gallery .width-4 {
          width: 25%;
        }

        .gallery > div > div > div:first-child {
          background: url('/i/vitrine-image-placeholder.svg') no-repeat 50% 50%/50% auto #eef0f2;
          line-height: 0;
          position: relative;

          visibility: hidden;
          opacity: 0;
          transform: translateY(50px);
        }
          .gallery > div > div > div:first-child.unveil-loaded {
            visibility: unset;
            opacity: 1;
            transform: translateY(0);
            transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
          }

          .gallery img {
            width: 100%;
            height: 0px;
            opacity: 0;
            transition: opacity 0.2s ease 0.1s;
          }
            .gallery > div > div > div:first-child img.unveil-loaded {
              height: unset;
              padding-top: 0px !important;
              opacity: 1;
            }


        .gallery > div > div > div + div {
          padding: 12px 0px 0px;

          visibility: hidden;
          opacity: 0;
          transform: translateY(50px);
        }
          .gallery > div > div > div + div.unveil-loaded {
            visibility: unset;
            opacity: 1;
            transform: translateY(0);
            transition: visibility 0s ease 0s, opacity 0.3s ease 0.18s, transform 0.3s ease 0.18s;
          }
          .gallery > div > div.even > div + div.unveil-loaded {
            transition: visibility 0s ease 0s, opacity 0.3s ease 0.05s, transform 0.3s ease 0.05s;
          }


      .gallery .gallery-notice {
        margin: -40px 0px 0px;
      }


    .photo-text .zoom-image:after,
    .gallery .zoom-image:after,
    .gallery-list .vitrine-item-image > .zoom-image:after,
    .gallery-list .vitrine-item-image > span > .zoom-image > span:after,
    .video-frame > a:after {
      content: '';
      background: url('/i/icon-button-plus.svg') no-repeat 50% 50%/24px rgba(255, 255, 255, 0.6);
      border-color: #fff;
      box-shadow: 3px 1px 6px 0px rgba(0, 0, 0, 0.1);
      margin: auto;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      position: absolute;
      transition: background-color 0.3s ease 0s;
    }
    .video-frame > a:after {
      background-image: url('/i/icon-button-play.svg');
      background-size: 18px;
    }
      .photo-text .zoom-image:hover:after,
      .gallery .zoom-image:hover:after,
      .gallery-list .vitrine-item-image > .zoom-image:hover:after,
      .gallery-list .vitrine-item-image > span > .zoom-image:hover > span:after,
      .video-frame > a:hover:after {
        background-color: #fff;
      }



    .vitrine-group {
      text-align: center;
    }

    .vitrine-group-text {
      text-align: center;

      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }
      .vitrine-group-text.unveil-loaded {
        visibility: unset;
        opacity: 1;
        transform: translateY(0);
        transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
      }


    .vitrine {
      padding: 0px 0px 55px;
      position: relative;

      visibility: hidden;
      opacity: 0;
      transform: translateY(50px);
    }
      div:not(.vitrine-grouped) > .vitrine + .vitrine {
        padding-top: 55px;
      }

      .vitrine.unveil-loaded {
        visibility: unset;
        opacity: 1;
        transform: translateY(0);
        transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
      }

      .vitrine > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-content: flex-start;
        align-items: stretch;
        margin: -30px 0px 0px -30px;
      }
        .vitrine-item {
          width: 33.33333333%;
          padding: 30px 0px 0px 30px;
          position: relative;
        }
          .slick:not(.slick-initialized) .vitrine-item {
            min-width: 33.33333333%;
          }

          .vitrine-item > div,
          .vitrine-item > label {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: stretch;
            width: 100%;
            height: 100%;
            position: relative;

            transition: box-shadow 0.3s ease-in-out 0s;
          }
          .portfolio.vitrine .vitrine-item > div,
          .portfolio.slick:not(.slick-initialized) .vitrine-item > div,
          .portfolio.slick .vitrine-item.slick-active > div,
          .portfolio.slick .vitrine-item.slick-active-fix > div,
          .gallery-list.vitrine .vitrine-item > div,
          .gallery-list.slick:not(.slick-initialized) .vitrine-item > div,
          .gallery-list.slick .vitrine-item.slick-active > div,
          .gallery-list.slick .vitrine-item.slick-active-fix > div,
          .video-list.vitrine .vitrine-item > div,
          .video-list.slick:not(.slick-initialized) .vitrine-item > div,
          .video-list.slick .vitrine-item.slick-active > div,
          .video-list.slick .vitrine-item.slick-active-fix > div,
          .photo-text:not(.slick-initialized) .vitrine-item > div,
          .photo-text .vitrine-item.slick-active > div,
          .photo-text .vitrine-item.slick-active-fix > div,
          .responses.vitrine .vitrine-item > div,
          .responses.slick:not(.slick-initialized) .vitrine-item > div,
          .responses.slick .vitrine-item.slick-active > div,
          .responses.slick .vitrine-item.slick-active-fix > div,
          .design-tariff-list.vitrine .vitrine-item > div,
          .contacts-info > div:first-child > div {
            box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06), 0px 40px 30px -15px rgba(0, 0, 0, 0.13);
          }
            .dark-bg .portfolio.vitrine .vitrine-item > div,
            .dark-bg .portfolio.slick:not(.slick-initialized) .vitrine-item > div,
            .dark-bg .portfolio.slick .vitrine-item.slick-active > div,
            .dark-bg .portfolio.slick .vitrine-item.slick-active-fix > div,
            .dark-bg .gallery-list.vitrine .vitrine-item > div,
            .dark-bg .gallery-list.slick:not(.slick-initialized) .vitrine-item > div,
            .dark-bg .gallery-list.slick .vitrine-item.slick-active > div,
            .dark-bg .gallery-list.slick .vitrine-item.slick-active-fix > div,
            .dark-bg .video-list.vitrine .vitrine-item > div,
            .dark-bg .video-list.slick:not(.slick-initialized) .vitrine-item > div,
            .dark-bg .video-list.slick .vitrine-item.slick-active > div,
            .dark-bg .video-list.slick .vitrine-item.slick-active-fix > div {
              box-shadow: 0px 7px 40px 0px rgba(0, 0, 0, 0.3);
            }

            .vitrine-item-labels {
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              align-items: flex-start;
              font-size: 14px;
              top: 0px;
              left: 0px;
              right: 0px;
              position: absolute;
            }
              .vitrine-item-labels > div {
                display: flex;
                flex-flow: row wrap;
                justify-content: flex-start;
                align-items: flex-start;
              }
              .vitrine-item-labels > div + div {
                justify-content: flex-end;
              }
                .vitrine-item-labels > div > div {
                  color: #fff;
                  background: #262526;
                  box-shadow: 0px 2px 8px 1px rgb(38, 37, 38, 0.3);
                  padding: 4px 10px;
                  margin: 15px 0px 0px 15px;
                  z-index: 2;
                }


            .vitrine-item-image {
              background: url('/i/vitrine-image-placeholder.svg') no-repeat 50% 50%/50% auto #eef0f2;
              font-size: 0px;
              padding: 64% 0% 0%;
              position: relative;
              order: 1;
              z-index: 1;
            }
              .vitrine-item-image > a,
              .vitrine-item-image > span {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: stretch;
                background: no-repeat 50% 50%/cover;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                z-index: 1;
              }
              .vitrine-item-image > a,
              .vitrine-item-image > span > a {
                cursor: pointer;
              }

              .vitrine-item-image > a:not([data-lazy-src]):hover,
              .vitrine-item-image > span:not([data-lazy-src]):hover {
                background: #eef0f2;
              }

              .vitrine-item-image > a[data-lazy-src],
              .vitrine-item-image > span[data-lazy-src] {
                opacity: 0;
              }
                .vitrine-item-image > a[data-lazy-src].unveil-loaded,
                .vitrine-item-image > span[data-lazy-src].unveil-loaded {
                  opacity: 1;
                  transition: opacity 0.3s ease 0s;
                }

                .vitrine-item-image > a > a,
                .vitrine-item-image > a > span,
                .vitrine-item-image > span > a,
                .vitrine-item-image > span > span {
                  flex: 1;
                  padding: 0px 0px 15px;
                }
                .vitrine-item-image.with-address > a > a,
                .vitrine-item-image.with-address > a > span,
                .vitrine-item-image.with-address > span > a,
                .vitrine-item-image.with-address > span > span {
                  padding: 0px 0px 50px;
                }

                  .vitrine-item-image > a > a:first-child,
                  .vitrine-item-image > a > span:first-child,
                  .vitrine-item-image > span > a:first-child,
                  .vitrine-item-image > span > span:first-child {
                    border-left: 8px solid transparent;
                  }
                  .vitrine-item-image > a > a:last-child,
                  .vitrine-item-image > span > a:last-child,
                  .vitrine-item-image > a > span:last-child,
                  .vitrine-item-image > span > span:last-child,
                  .vitrine-item-image > a > a:nth-child(6),
                  .vitrine-item-image > span > a:nth-child(6) ,
                  .vitrine-item-image > a > span:nth-child(6),
                  .vitrine-item-image > span > span:nth-child(6) {
                    border-right: 8px solid transparent;
                  }
                  .vitrine-item-image > a > a:nth-child(6) ~ *,
                  .vitrine-item-image > a > span:nth-child(6) ~ *,
                  .vitrine-item-image > span > a:nth-child(6) ~ *,
                  .vitrine-item-image > span > span:nth-child(6) ~ * {
                    display: none;
                  }

                  .vitrine-item-image > a > a:after,
                  .vitrine-item-image > a > span:after,
                  .vitrine-item-image > span > a:after,
                  .vitrine-item-image > span > span:after {
                    content: '';
                    display: block;
                    border-bottom: 3px solid #fff;
                    height: 100%;
                    margin: 0px 8px;
                    position: relative;
                    opacity: 0;
                    z-index: 3;

                    transition: opacity 0.2s ease 0s;
                  }
                    .vitrine-item-image > a:hover > a:after,
                    .vitrine-item-image > a:hover > span:after,
                    .vitrine-item-image > span:hover > a:after,
                    .vitrine-item-image > span:hover > span:after {
                      opacity: 0.5;
                    }
                    .vitrine-item-image > a:hover > a:hover:after,
                    .vitrine-item-image > a:hover > span:hover:after,
                    .vitrine-item-image > span:hover > a:hover:after,
                    .vitrine-item-image > span:hover > span:hover:after {
                      opacity: 1;

                      transition: none;
                    }

                  .vitrine-item-image > a > * > span,
                  .vitrine-item-image > span > * > span {
                    background: no-repeat 50% 50%/cover transparent;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    pointer-events: none;
                    opacity: 0;
                    z-index: 1;

                    transition: opacity 0.2s ease 0s;
                  }
                    .vitrine-item-image > a:not(:hover) > a:first-child > span.unveil-loaded,
                    .vitrine-item-image > a:not(:hover) > span:first-child > span.unveil-loaded,
                    .vitrine-item-image > span:not(:hover) > a:first-child > span.unveil-loaded,
                    .vitrine-item-image > span:not(:hover) > span:first-child > span.unveil-loaded,
                    .vitrine-item-image > a:hover > a:hover > span,
                    .vitrine-item-image > a:hover > span:hover > span,
                    .vitrine-item-image > span:hover > a:hover > span,
                    .vitrine-item-image > span:hover > span:hover > span {
                      opacity: 1;
                    }


            .vitrine-item-text {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: stretch;
              text-align: center;
              height: 100%;
              padding: 15px;
              margin: 0px;
              position: relative;
              order: 2;
              z-index: 2;
            }
              .vitrine-item-name {
                font-size: 20px;
                text-decoration: none;
                position: relative;
                order: 2;
                z-index: 2;
              }
                .vitrine-item-name a,
                .vitrine-item-name span {
                  text-transform: uppercase;
                }
                .vitrine-item-name a {
                  cursor: pointer;
                }
                  .vitrine-item-name a:hover,
                  .vitrine-item-image:hover + .vitrine-item-name a,
                  .vitrine-item-image:hover + .vitrine-item-text .vitrine-item-name a {
                    text-decoration: underline;
                  }

              .vitrine-item-notice {
                order: 3;
              }
              .vitrine-item-name + .vitrine-item-notice {
                padding: 13px 0px 0px;
              }

                .vitrine-item-notice :last-child {
                  margin-bottom: 0px;
                }

              .vitrine-item-icons {
                display: flex;
                flex-flow: row wrap;
                justify-content: space-around;
                align-content: flex-start;
                align-items: flex-start;
                line-height: 1.3;
                margin: -5px 0px 0px -15px;
                order: 3;
              }
                .vitrine-item .vitrine-item-icons {
                  margin-left: -5px;
                }

                .vitrine-item-icons > div {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 15px 0px 0px 15px;
                }
                  .vitrine-item .vitrine-item-icons > div {
                    flex: 1 3 15%;
                    flex-direction: column;
                    font-size: 13px;
                    padding-left: 5px;
                  }

                  .vitrine-item-icons > div > div:first-child {
                    align-self: flex-start;
                    background: no-repeat 50% 50%/contain transparent;
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                  }
                    .vitrine-item .vitrine-item-icons > div > div:first-child {
                      align-self: unset;
                    }


                .vitrine-item-icons .vitrine-icon-address {
                  flex: 1 0 100%;
                }
                  .vitrine-item .vitrine-item-icons .vitrine-icon-address {
                    flex-direction: row;
                    justify-content: center;
                    font-size: 14px;
                    background: rgba(0, 0, 0, 0.6);
                    padding: 8px 15px;
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    bottom: 100%;
                  }
                    .vitrine-item-icons .vitrine-icon-address > div:first-child {
                      background-image: url('/i/icon-placemark.svg');
                    }
                      .vitrine-item .vitrine-item-icons .vitrine-icon-address > div:first-child {
                        background-image: url('/i/icon-placemark-bold--white.svg');
                        width: 18px;
                        min-width: 18px;
                        height: 18px;
                      }

                  .vitrine-item .vitrine-item-icons .vitrine-icon-square,
                  .vitrine-item .vitrine-item-icons .vitrine-icon-rooms,
                  .vitrine-item .vitrine-item-icons .vitrine-icon-time {
                    flex-shrink: 7;
                  }
                  .vitrine-item .vitrine-item-icons .vitrine-icon-time {
                    white-space: nowrap;
                  }

                    .vitrine-item-icons .vitrine-icon-square > div:first-child {
                      background-image: url('/i/icon-square.svg');
                    }
                      .vitrine-item .vitrine-item-icons .vitrine-icon-square > div:first-child {
                        background-image: url('/i/icon-square--white.svg');
                      }

                    .vitrine-item-icons .vitrine-icon-rooms > div:first-child {
                      background-image: url('/i/icon-rooms.svg');
                    }
                      .vitrine-item .vitrine-item-icons .vitrine-icon-rooms > div:first-child {
                        background-image: url('/i/icon-rooms--white.svg');
                      }

                    .vitrine-item-icons .vitrine-icon-time > div:first-child {
                      background-image: url('/i/icon-time.svg');
                    }
                      .vitrine-item .vitrine-item-icons .vitrine-icon-time > div:first-child {
                        background-image: url('/i/icon-time--white.svg');
                      }

                    .vitrine-item-icons .vitrine-icon-price > div:first-child {
                      background-image: url('/i/icon-price.svg');
                    }
                      .vitrine-item .vitrine-item-icons .vitrine-icon-price > div:first-child {
                        background-image: url('/i/icon-price--white.svg');
                      }

                    .vitrine-item-icons .vitrine-icon-work-type > div:first-child {
                      background-image: url('/i/icon-work-type.svg');
                    }
                      .vitrine-item .vitrine-item-icons .vitrine-icon-work-type > div:first-child {
                        background-image: url('/i/icon-work-type--white.svg');
                      }

                    .vitrine-item-icons .vitrine-icon-style > div:first-child {
                      background-image: url('/i/icon-style.svg');
                    }
                      .vitrine-item .vitrine-item-icons .vitrine-icon-style > div:first-child {
                        background-image: url('/i/icon-style--white.svg');
                      }


                  .vitrine-item-icons > div > div + div {
                    padding: 0px 0px 0px 8px;
                  }
                    .vitrine-item .vitrine-item-icons > div > div + div {
                      padding: 4px 0px 0px;
                    }

                    .vitrine-item .vitrine-item-icons .vitrine-icon-address > div + div {
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                      padding: 0px 0px 0px 6px;
                    }




    .services .vitrine-item {
      width: 25%;
    }
      .services.slick:not(.slick-initialized) .vitrine-item {
        min-width: 25%;
      }



    .advantages {
      position: relative;
    }
      .advantages.slick .vitrine-item {
        float: left;
      }
        .advantages .vitrine-item-image {
          background: none;
        }
          .advantages .vitrine-item-image:before {
            content: '';
            display: block;
            background: #eef0f2;
            border-radius: 50%;
            width: 58.6%;
            height: 0px;
            padding: 58.6% 0% 0%;
            margin: auto;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            position: absolute;
          }

    .advantages .slick-track {
      align-items: flex-start;
    }
      .advantages .slick-controls {
        display: block;
      }
        .advantages .slick-dots {
          display: none;
        }

        .advantages .slick-arrows {
          display: block;
          position: unset;
        }
          .advantages .slick-arrows .button-style {
            margin: calc((((100% + 30px) / 3) - 30px) * 0.32 + 50px) 0px 0px;
            top: 0px;
            left: 0px;
            transform: translateY(-50%);
            position: absolute;
          }
          .advantages .slick-arrows .button-style.next {
            left: auto;
            right: 0px;
          }



    .portfolio .vitrine-item,
    .gallery-list .vitrine-item,
    .video-list .vitrine-item,
    .design-tariff-list .vitrine-item-name {
      color: #fff;
    }
      .portfolio .vitrine-item > div,
      .gallery-list .vitrine-item > div,
      .video-list .vitrine-item > div,
      .design-tariff-list .vitrine-item > div {
        background: #262526;
      }
        .portfolio .vitrine-item-name,
        .gallery-list .vitrine-item-name {
          font-size: 18px;
          min-height: 51px;
        }


    .portfolio-top-info {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0px 0px 100px;
      margin: -50px 0px 0px -100px;
    }
      .portfolio-notice {
        flex: 1 1 55%;
        padding: 50px 0px 0px 100px;

        visibility: hidden;
        opacity: 0;
        transform: translateY(50px);
      }
        .portfolio-top-info.unveil-loaded .portfolio-notice {
          visibility: unset;
          opacity: 1;
          transform: translateY(0);
          transition: visibility 0s ease 0s, opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
        }


      .portfolio-params {
        flex: 1 1 45%;
        padding: 40px 0px 0px 100px;

        visibility: hidden;
        opacity: 0;
        transform: translateY(50px);
      }
        .portfolio-top-info.unveil-loaded .portfolio-params {
          visibility: unset;
          opacity: 1;
          transform: translateY(0);
          transition: visibility 0s ease 0s, opacity 0.3s ease 0.1s, transform 0.3s ease 0.1s;
        }
        .portfolio-top-info.unveil-loaded .portfolio-notice + .portfolio-params {
          transition: visibility 0s ease 0s, opacity 0.3s ease 0.2s, transform 0.3s ease 0.2s;
        }

        .portfolio-params .vitrine-item-icons {
          justify-content: space-between;
        }
          .portfolio-notice + .portfolio-params .vitrine-item-icons {
            justify-content: flex-start;
          }

          .portfolio-params .vitrine-item-icons .vitrine-icon-address {
            flex: initial;
          }

        .portfolio-audio {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 20px 0px 0px;
        }
          .portfolio-notice + .portfolio-params .vitrine-item-icons + .portfolio-audio {
            padding: 30px 0px 0px;
          }

          .portfolio-audio.with-label:before {
            content: 'Аудиоотзыв:';
            margin: 0px 12px 0px 0px;
            white-space: nowrap;
          }

          .portfolio-audio > div {
            flex: 1;
          }



    .portfolio-gallery > div {
      padding-bottom: 60px;
    }
      .portfolio-gallery > div > div {
        padding-bottom: 20px;
      }
      .portfolio-gallery .with-notice {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-left: 0px;
      }
        .portfolio-gallery .with-notice.even {
          flex-direction: row-reverse;
        }

        .portfolio-gallery .with-notice > div:first-child {
          width: calc(50% - 30px);
          margin: 0px 0px 0px 30px;
        }

        .portfolio-gallery .with-notice > div + div {
          width: 50%;
          padding: 0px 0px 0px 80px;
        }



    .team .vitrine-item {
      width: 25%;
    }
      .team.slick:not(.slick-initialized) .vitrine-item {
        min-width: 25%;
      }

      .team .vitrine-item-image {
        background-size: 75% auto;
        border-radius: 50%;
        width: 60%;
        padding: unset;
        margin: auto;
      }
        .team .vitrine-item-image:before {
          content: '';
          display: block;
          padding: 100% 0% 0%;
          border-radius: 50%;
        }

        .team .vitrine-item-image a,
        .team .vitrine-item-image span {
          border-radius: 50%;
        }
          .team .vitrine-item-image a:before,
          .team .vitrine-item-image a:after {
            content: '';
            display: block;
            border: 1px solid #bbc6d0;
            border-radius: 50%;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            position: absolute;
            opacity: 0;
            transition: opacity 0.3s ease 0s, margin 0s ease 0.2s;
          }
          .team .vitrine-item > div:hover .vitrine-item-image a:before {
            opacity: 1;
            margin: -8px;
            transition: opacity 0.2s ease 0s, margin 0.1s ease 0s;
          }
          .team .vitrine-item > div:hover .vitrine-item-image a:after {
            opacity: 1;
            margin: -18px;
            transition: opacity 0.2s ease 0.08s, margin 0.2s ease 0s;
          }

      .team .vitrine-item-text {
        justify-content: space-between;
      }
        .team .vitrine-item-name {
          font-size: 28px;
        }
          .team .vitrine-item-name a,
          .team .vitrine-item-name span {
            text-transform: none;
          }

        .team .vitrine-item-notice .button-style {
          font-weight: 400;
          border-color: #e1e7ec;
          box-shadow: 0px 0px 6px 0px rgba(38, 37, 38, 0.1);
          padding: 10px 25px;
          cursor: unset;
        }
        .team .vitrine-item-notice .button-style,
        .team .vitrine-item-notice .button-style:hover {
          color: #959595;
          background: #fff;
        }



    .responses .vitrine-item > div,
    .response-popup > div {
      display: grid;
      grid-template-rows: auto 1fr auto;
      grid-template-columns: auto 1fr;
      align-content: space-between;
      background: #fff;
      padding: 22px 30px;
      cursor: pointer;
    }
    .response-popup > div {
      padding: 5px 10px;
    }

    .is-desktop-events .responses .vitrine-item > div {
      transition: box-shadow 0.3s ease 0s, transform 0.3s ease 0s;
    }
      .is-desktop-events .responses .vitrine-item > div:hover,
      .is-desktop-events .responses.slick .vitrine-item.slick-active > div:hover {
        box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.06), 0px 45px 45px -10px rgba(0, 0, 0, 0.11);
        transform: translateY(-6px);
      }

      .response-date {
        grid-column: 1 / 3;
        background: url('/i/icon-quote.svg') no-repeat 0% 50%/auto min(16px, 100%);
        color: #9ca8b5;
        font-size: 15px;
        font-weight: 300;
        text-align: right;
        margin: 0px 0px 10px;
        order: 1;
      }


      .responses .vitrine-item-image,
      .response-popup .vitrine-item-image {
        grid-column: 1;
        background: none;
        width: 60px;
        max-width: 60px;
        padding: 0px;
      }
      .response-popup .vitrine-item-image {
        order: 4;
        padding: 15px 0px 0px;
      }
        .responses .vitrine-item-image span,
        .response-popup .vitrine-item-image span {
          display: block;
          border-radius: 50%;
          height: 0px;
          padding: 100% 0% 0%;
          position: unset;
          top: unset;
          left: unset;
          right: unset;
          bottom: unset;
        }


      .responses .vitrine-item-name,
      .response-popup .vitrine-item-name {
        grid-column: 1 / 3;
        order: 4;
        padding: 15px 0px 0px;
      }
      .response-popup .vitrine-item-image ~ .vitrine-item-name {
        align-self: center;
        grid-column-start: 2;
        padding-left: 12px;
      }
        .responses .vitrine-item-name span,
        .response-popup .vitrine-item-name span {
          text-transform: none;
        }


      .responses .vitrine-item-notice {
        grid-column: 1 / 3;
        font-size: 15px;
        max-height: 150px;
        padding: 0px;
        overflow: hidden;
        position: relative;
      }
        .responses .vitrine-item-image ~ .vitrine-item-notice {
          grid-column-start: 2;
          padding-left: 15px;
        }

        .responses .vitrine-item-notice:after {
          content: '';
          background: linear-gradient(0deg, #fff, rgba(255,255,255,0));
          display: block;
          height: 65px;
          max-height: 44%;
          left: 0px;
          right: 0px;
          bottom: 0px;
          position: absolute;
        }

      .response-popup .vitrine-item-notice {
        grid-column: 1 / 3;
        padding: 0px;
      }



    .photo-text-unwrapper > div {
      padding: 20px 0px 0px;
      margin: -20px 0px 0px;
      overflow: hidden;
    }

    .photo-text-unwrapper .photo-text .slick-slider {
      overflow: unset;
    }
      .photo-text:not(.slick-initialized) > div:first-child {
        align-items: flex-start;
      }

      .photo-text .slick-track {
        align-items: flex-start;
      }
        .photo-text .vitrine-item {
          width: 100%;
        }
          .photo-text.slick:not(.slick-initialized) .vitrine-item {
            min-width: 100%;
          }
        .photo-text.width-2 .vitrine-item {
          width: 50%;
        }
          .photo-text.slick.width-2:not(.slick-initialized) .vitrine-item {
            min-width: 50%;
          }
        .photo-text.width-3 .vitrine-item {
          width: 33.33333333%;
        }
          .photo-text.slick.width-3:not(.slick-initialized) .vitrine-item {
            min-width: 33.33333333%;
          }
        .photo-text.width-4 .vitrine-item {
          width: 25%;
        }
          .photo-text.slick.width-4:not(.slick-initialized) .vitrine-item {
            min-width: 25%;
          }

          .photo-text:not(.width-2):not(.width-3):not(.width-4):not(.slick-initialized) .vitrine-item ~ .vitrine-item,
          .photo-text.width-2:not(.slick-initialized) .vitrine-item:nth-child(2) ~ .vitrine-item,
          .photo-text.width-3:not(.slick-initialized) .vitrine-item:nth-child(3) ~ .vitrine-item,
          .photo-text.width-4:not(.slick-initialized) .vitrine-item:nth-child(4) ~ .vitrine-item {
            max-height: 1px;
          }

          .photo-text.slick-initialized .vitrine-item {
            float: left;
            min-width: unset;

            opacity: 0.3;
            transition: opacity 0.3s ease-in-out 0s;
          }
            .photo-text.slick-initialized .vitrine-item.slick-active,
            .photo-text.slick-initialized .vitrine-item.slick-active-fix {
              opacity: 1;
            }

          .photo-text .vitrine-item > div {
            flex-direction: row;
            justify-content: center;
            align-items: flex-start;
            background: #eef0f2;
          }
            .photo-text.width-2 .vitrine-item > div,
            .photo-text.width-3 .vitrine-item > div,
            .photo-text.width-4 .vitrine-item > div {
              flex-direction: column;
              justify-content: flex-start;
              align-items: stretch;
              padding: 0px 0px 30px;
            }

            .photo-text .vitrine-item-text {
              text-align: left;
              width: 50%;
              min-width: 50%;
              padding: 30px 40px 50px;
            }
              .photo-text.width-2 .vitrine-item-text,
              .photo-text.width-3 .vitrine-item-text,
              .photo-text.width-4 .vitrine-item-text {
                width: unset;
                padding: 10px 30px 0px;
              }

              .photo-text .vitrine-item-notice {
                padding: 20px 0px 0px;
              }
                .photo-text .vitrine-item-name + .vitrine-item-notice {
                  padding: 10px 0px 0px;
                }


            .photo-text .vitrine-item-image {
              background-color: unset;
              background-size: 25% auto;
              text-align: center;
              width: 100%;
              padding: 30px;
            }
              .photo-text.width-2 .vitrine-item-image,
              .photo-text.width-3 .vitrine-item-image,
              .photo-text.width-4 .vitrine-item-image {
                width: unset;
                padding: 30px 30px 0px;
              }

              .photo-text .vitrine-item-image > a,
              .photo-text .vitrine-item-image > span {
                display: inline-block;
                width: 100%;
                position: relative;
              }
                .photo-text .vitrine-item-image img {
                  display: unset;
                  width: 100%;
                  height: 0px;
                  opacity: 0;
                }
                  .photo-text .vitrine-item-image img.unveil-loaded {
                    height: unset;
                    padding-top: 0px !important;
                    opacity: 1;
                    transition: opacity 0.3s ease 0s;
                  }



    .video-list.width-4 .vitrine-item {
      width: 25%;
    }
      .video-list.slick.width-4:not(.slick-initialized) .vitrine-item {
        min-width: 25%;
      }
    .video-list.width-2 .vitrine-item {
      width: 50%;
    }
      .video-list.slick.width-2:not(.slick-initialized) .vitrine-item {
        min-width: 50%;
      }
    .video-list.width-1 .vitrine-item {
      width: 100%;
    }
      .video-list.slick.width-1:not(.slick-initialized) .vitrine-item {
        min-width: 100%;
      }

      .video-list .vitrine-item-image {
        padding: 56% 0% 0%;
      }
        .video-list .vitrine-item-image .video-frame {
          padding: unset;
          position: unset;
        }



    .price-table {
      padding: 0px 0px 50px;
    }
      .price-table-items {
        line-height: 1.2;
        position: relative;

        visibility: hidden;
        opacity: 0;
        transform: translateY(80px);
      }
        .price-table-items.unveil-loaded {
          visibility: unset;
          opacity: 1;
          transform: translateY(0px);

          transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
        }

        .price-table-items > div {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;

          visibility: hidden;
          opacity: 0;
          transform: translateX(-50px);

          transition: visibility 0s ease 0.3s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
        }
        .price-table-items > div.active ~ div {
          transform: translateX(50px);
        }
        .price-table-items > div.active {
          position: unset;
          visibility: unset;
          opacity: 1;
          transform: translateX(0px);

          transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
        }

          .price-table-items > div > div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: stretch;
            border: solid #e1e7ec;
            border-width: 0px 0px 2px;
          }
            .price-name,
            .price-price,
            .price-unit {
              padding: 10px 8px;
              flex-basis: 100%;
              flex-shrink: 4;
            }
            .price-name {
              padding-left: 30px;
            }
            .price-price,
            .price-unit {
              text-align: center;
              flex-shrink: 7;
            }

          .price-table-head {
            font-size: 20px;
            font-weight: 700;
            background: #e1e7ec;
            border: none !important;
          }
            .price-table-head .price-name,
            .price-table-head .price-price,
            .price-table-head .price-unit {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: flex-end;
            }
            .price-table-head .price-price,
            .price-table-head .price-unit {
              justify-content: center;
            }

          .price-table-subheader {
            font-weight: 600;
          }



    .design-tariff-table {
      padding: 0px 0px 50px;
      visibility: hidden;
      opacity: 0;
      transform: translateY(80px);
    }
      .design-tariff-table.unveil-loaded {
        visibility: unset;
        opacity: 1;
        transform: translateY(0px);
        transition: visibility 0s ease 0s, opacity 0.3s ease 0s, transform 0.3s ease 0s;
      }

      .design-tariff-table > div {
        background: #fff;
        box-shadow: 0px 7px 40px 0px rgb(0, 0, 0, 0.2);
      }

      .design-tariff-table table {
        border-collapse: separate;
        width: 100%;
        overflow: hidden;
        position: relative;
        z-index: 1;
      }

      .design-tariff-table th,
      .design-tariff-table td {
        text-align: center;
        vertical-align: middle;
        width: 10%;
        padding: 18px 25px;
        position: relative;
      }
        .design-tariff-table th:first-child,
        .design-tariff-table td:first-child {
          text-align: left;
          width: 30%;
        }

      .design-tariff-table thead th {
        color: #fff;
        background: #262526;
        border: solid #262526;
        border-width: 0px 1px;
        font-size: 20px;
        font-weight: 700;
        padding: 25px;
      }

      .design-tariff-table tfoot td,
      .design-tariff-table tbody td {
        border: solid #e1e7ec;
        border-width: 0px 1px 2px;
      }
        .design-tariff-table tfoot td:first-child,
        .design-tariff-table tbody td:first-child {
          border-left-width: 2px;
        }
        .design-tariff-table tfoot td:last-child,
        .design-tariff-table tbody td:last-child {
          border-right-width: 2px;
        }

      .design-tariff-table tfoot tr:hover,
      .design-tariff-table table:not(:hover) tfoot tr:first-child + tr,
      .design-tariff-table tbody tr:hover {
        background: #f5f7f9;
        transition: background 0.1s ease 0s;
      }

      .design-tariff-table thead th + th:after,
      .design-tariff-table tfoot td + td:after,
      .design-tariff-table tbody td + td:after {
        content: '';
        background: #f5f7f9;
        top: -9999px;
        left: 0px;
        right: 0px;
        bottom: -9999px;
        position: absolute;
        z-index: -1;

        opacity: 0;
        transition: opacity 0.1s ease 0.07s;
      }
        .design-tariff-table thead th + th:hover:after,
        .design-tariff-table table:not(:hover) thead th.highlighted:after,
        .design-tariff-table tfoot td + td:hover:after,
        .design-tariff-table tbody td + td:hover:after {
          opacity: 1;
          transition: opacity 0.1s ease 0s;
        }

      .design-tariff-table tfoot td:first-child,
      .design-tariff-table tfoot tr:first-child td,
      .design-tariff-table tfoot tr + tr td,
      .design-tariff-table tbody td:first-child {
        font-weight: 600;
      }
        .design-tariff-table tfoot td > div[id],
        .design-tariff-table tbody td:first-child > div[id] {
          display: none;
        }

        .design-tariff-table tfoot td > a,
        .design-tariff-table tbody td:first-child > a,
        .design-tariff-list .vitrine-item-notice a[data-src^="#design-tariff-content"] {
          border-bottom: 1px dashed #262526;
          transition: border-color 0.2s ease 0s;
        }
          .design-tariff-table tfoot td > a:hover,
          .design-tariff-table tbody td:first-child > a:hover,
          .design-tariff-list .vitrine-item-notice a[data-src^="#design-tariff-content"]:hover {
            border-bottom-color: transparent;
          }


        .design-tariff-table tfoot tr:first-child + tr td > span:first-child {
          display: block;
          font-weight: 400;
          text-decoration: line-through;
          margin: 0px 0px 5px;
        }
        .design-tariff-table tfoot tr:first-child + tr td > span + span {
          font-size: 20px;
        }


        .design-tariff-table tbody .available,
        .design-tariff-list .vitrine-item-notice ul.ul li:before,
        .form-steps .vitrine-item > label > input + .vitrine-item-image:before {
          display: inline-block;
          background: url('/i/icon-tick.svg') no-repeat 50% 50%/contain #262526;
          border: solid #262526;
          border-width: 6px 6px 7px 7px;
          border-radius: 50%;
          box-sizing: border-box;
          width: 28px;
          height: 28px;
        }


    .design-tariff-list {
      display: none;
    }
      .design-tariff-list .vitrine-item-text {
        padding: 0px;
      }
        .design-tariff-list .vitrine-item-name {
          padding: 12px 15px;
        }

        .design-tariff-item-price {
          order: 2;
          background: #f5f7f9;
          border-bottom: 1px solid #e1e7ec;
          padding: 15px;
        }
          .design-tariff-item-price > span:first-child {
            font-size: 19px;
            font-weight: 600;
          }
          .design-tariff-item-price > span + span {
            display: block;
            font-size: 15px;
            text-align: center;
            text-decoration: line-through;
            margin: 4px 0px 0px;
          }

        .design-tariff-list .vitrine-item-notice {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: stretch;
          flex-grow: 1;
          background: #fff;
          padding: 15px 15px 0px;
        }
          .design-tariff-list .vitrine-item-notice > div {
            padding: 0px 0px 25px;
          }

          .design-tariff-list .vitrine-item-notice > div:first-child {
            flex-grow: 1;
            text-align: left;
          }
            .design-tariff-item-services-contains {
              font-weight: 600;
              margin: 0px 0px 12px;
            }

            .design-tariff-list .vitrine-item-notice ul.ul li {
              padding-left: 34px;
            }
              .design-tariff-list .vitrine-item-notice ul.ul li:before {
                border-width: 5px 5px 6px 6px;
                vertical-align: top;
                width: 24px;
                height: 24px;
                margin: 0px 10px 0px -34px;
              }


            .design-tariff-list .vitrine-item-notice a[data-src^="#design-tariff-content"] {
              font-weight: 600;
            }


    .callback-price-text-form-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      margin: -30px 0px 0px -30px;
    }
      .callback-price-text-form-container > div {
        flex-grow: 1;
        padding: 30px 0px 0px 30px;
      }
      .callback-price-text-form-container > div:first-child {
        min-width: 25%;
        max-width: 25%;
        padding-right: 10px;
      }
        .callback-price-text-form-container > div:first-child > div:first-child {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          font-size: 1.125em;
          padding: 10px 0px 30px;
        }
          .callback-price-text-form-container > div:first-child > div:first-child > div:first-child {
            align-self: flex-start;
            min-width: 33.33333333%;
            max-width: 33.33333333%;
            margin: 0px 20px 0px 0px;
          }
            .callback-price-text-form-container > div:first-child > div:first-child > div:first-child span {
              display: block;
              background: url('/f/form/callback_price_image/supervisor_photo.webp') no-repeat 50% 50%/cover #eef0f2;
              border-radius: 50%;
            }
              .no-webp .callback-price-text-form-container > div:first-child > div:first-child > div:first-child span {
                background-image: url('/f/form/callback_price_image/supervisor_photo.jpg');
              }

              .callback-price-text-form-container > div:first-child > div:first-child > div:first-child span:after {
                content: '';
                display: block;
                height: 0px;
                padding: 100% 0% 0%;
              }

          .callback-price-text-form-container > div:first-child > div:first-child > div + div {
            padding: 10px 0px;
          }
            .callback-price-text-form-container > div:first-child > div:first-child > div + div > div:first-child {
              font-size: 1.11111111em;
              font-weight: 700;
            }
  /* Content block end */



  /* Contact page */
  .map-container {
    background: #eef0f2;
    overflow: hidden;
    position: relative;
  }
    .map-container:before {
      content: '';
      display: block;
      height: 0px;
      padding-top: 55%;
    }

    .map-container > div:before {
      content: 'Загрузка карты...';
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin: auto;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      position: absolute;
    }

    .map-container > div > div {
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      position: absolute;
    }
      .map-container > div > div .map-ground-pane {
        filter: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feColorMatrix type="saturate" values=".15"/><feComponentTransfer><feFuncR type="linear" slope="0.98" intercept="0.01"/><feFuncG type="linear" slope="0.98" intercept="0.01"/><feFuncB type="linear" slope="0.98" intercept="0.01"/></feComponentTransfer></filter></svg>#filter');
        -webkit-filter: grayscale(0.85) contrast(0.98);
        filter: grayscale(0.85) contrast(0.98);
      }



  .contacts-info {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: stretch;
    padding: 0px 0px 40px;
  }
    .contacts-info > div {
      width: 50%;
      min-width: 50%;
    }

    .contacts-info > div:first-child {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 45px 15px 55px 30px;
    }
      .contacts-info > div:first-child > div {
        background: #fff;
        font-size: 22px;
        width: 100%;
        max-width: 675px; /* wrapper half width = 1350px / 2 */
        padding: 0px 40px 40px 0px;
        z-index: 1;
      }
        .contacts-info > div:first-child > div > div {
          padding: 35px 0px 0px 40px;
        }
          .contacts-info .subsubheader-top {
            font-size: 28px;
            padding-bottom: 15px;
          }

          .contacts-phones {
            font-size: 26px;
            font-weight: 600;
          }

          .contacts-info .socials {
            flex-wrap: wrap;
            margin: -10px 0px 0px -15px;
          }
            .contacts-info .socials > div {
              padding: 15px 0px 0px 15px;
            }


    .contacts-info > div + div > div {
      width: 200%;
      min-width: 200%;
      height: 100%;
    }
      .contacts-info .map-container {
        height: 100%;
      }
        .contacts-info .map-container > div:before {
          right: 50%;
        }
  /* Contact page end */



  /* Footer */
  .footer-form {
    position: relative;
    z-index: 3;
  }
    .footer-form > div:before,
    .footer-form > div:after {
      display: none;
    }
      /*.footer-form > div > div:first-child,
      .footer-form > div > div + div {
        background-color: #595959;
      }*/

      .footer-form.invert > div > div:first-child:not([style]) {
        background-image: url('/i/footer-form-bg.webp');
      }
        .no-webp .footer-form.invert > div > div:first-child:not([style]) {
          background-image: url('/i/footer-form-bg.jpg');
        }


      .footer-form .leader-icon {
        right: 45px;
      }



  .footer-wrapper {
    color: #fff;
    background: #262526;
    margin: -160px 0px 0px;
  }
    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 130px 0px 30px;
      margin: 0px 50px 0px 0px;
      position: relative;
      z-index: 1;
    }
      .footer-info {
        padding: 20px 0px 0px 50px;
      }
        .footer-logo {
          line-height: 0;
        }
          .footer-logo img {
            width: 100%;
            max-width: min(274px, 100%);
          }

        .footer-slogan {
          padding: 15px 0px 0px;
        }

        .footer .socials {
          padding: 35px 0px 0px;
        }
          .footer .socials a {
            background-image: url('/i/socials--white.svg');
          }


      .footer-contact {
        font-size: 18px;
        line-height: 1.3;
        padding: 5px 0px 0px 50px;
      }
        .footer-contact > div {
          padding: 25px 0px 0px;
        }

        .footer-phones {
          font-size: 28px;
          line-height: 1.2;
        }


      .footer-menu {
        padding: 25px 0px 0px 50px;
      }
        .footer-menu li {
          padding: 5px 0px 0px;
        }
          .footer-menu a {
            text-decoration: underline;
            cursor: pointer;
          }
            .footer-menu a:hover {
              text-decoration: none;
            }



      .footer-bottom > .wrapper {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-start;
        border-top: 1px solid #595959;
        padding: 5px 50px 30px 0px;
      }
        .footer-bottom > .wrapper > div {
          text-align: center;
          padding: 25px 0px 0px 50px;
        }
  /* Footer end */


  #contacts-popup .header-phones {
    text-align: center;
    padding: 25px 0px 0px 25px;
  }
  #contacts-popup .socials {
    display: none;
    justify-content: center;
    padding: 10px 0px 0px 10px;
  }
    #contacts-popup .socials > div {
      padding: 15px 0px 0px 15px;
    }
  #contacts-popup .form-buttons {
    justify-content: center;
  }



  .bottom-sticky-panel {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 9;
  }
    .telegram-sticky-button-container {
      line-height: 0;
      margin: 0px 30px 30px auto;
    }
      .telegram-sticky-button-container .button-style {
        background: url('/i/telegram-sticky-button.svg') no-repeat transparent;
        background-size: cover;
        border: none;
        box-shadow: none;
        width: 68px;
        height: 68px;
        padding: 0px;
        margin: 0px -2px -2px 0px;
        overflow: hidden;

        -webkit-animation: 0.6s steps(30) 0.2s both;
        -o-animation: 0.6s steps(30) 0.2s both;
        animation: 0.6s steps(30) 0.2s both;
      }
        .telegram-sticky-button-container .button-style.play {
          -webkit-animation-name: telegram-sticky-button-play;
          -o-animation-name: telegram-sticky-button-play;
          animation-name: telegram-sticky-button-play;
        }
          @-webkit-keyframes telegram-sticky-button-play {
            0%   { background-position: 0% 0%; }
            100% { background-position: 100% 0%; }
          }
          @-o-keyframes telegram-sticky-button-play {
            0%   { background-position: 0% 0%; }
            100% { background-position: 100% 0%; }
          }
          @keyframes telegram-sticky-button-play {
            0%   { background-position: 0% 0%; }
            100% { background-position: 100% 0%; }
          }
/* Structure end */